import React from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import {GoogleReCaptchaProvider} from "react-google-recaptcha-v3";

import Login from "./pages/Login";
import Logout from "./pages/Logout";
import ForgetPassword from "./pages/ForgetPassword";
import ResetPassword from "./pages/ResetPassword";
import Verification from "./pages/Verification";
import MfaVerification from "./pages/Mfa";

import { AUTH_GROUP_LINKS, AUTH_GROUP_ROUTES } from "./config";
import LinkSent from "./pages/ForgetPassword/linkSent";
import SignUp from "./pages/Partners/SignUp";
import CreatingAccount from "./pages/Partners/CreatingAccount";
import Step1 from "./pages/Partners/Steps/Step1";
import Step2 from "./pages/Partners/Steps/Step2";
import VerificationInProgress from "./pages/Partners/VerificationInProgress";

export default function AuthRoutes() {
  return (
    <Routes>
      <Route
        path={AUTH_GROUP_ROUTES.LINK_LOGIN}
        element={<Login/>}
      />

      <Route
        path={AUTH_GROUP_ROUTES.LINK_RESET_PASSWORD}
        element={<ResetPassword/>}
      />

      <Route
        path={AUTH_GROUP_ROUTES.LINK_MFA}
        element={<MfaVerification/>}
      />

      <Route
        path={AUTH_GROUP_ROUTES.LINK_FORGOT_PASSWORD}
        element={<ForgetPassword/>}
      />

      <Route
        path={AUTH_GROUP_ROUTES.LINK_EXPIRED}
        element={<Verification/>}
      />

      <Route
        path={AUTH_GROUP_ROUTES.LINK_SENT}
        element={<LinkSent/>}
      />

      <Route
         path={AUTH_GROUP_ROUTES.LINK_PARTNER_SIGN_UP}
         element={
             <GoogleReCaptchaProvider reCaptchaKey="6Ld9uJInAAAAAMLHpf8BItQ_FRYn4baETFfcJRmE">
                <SignUp/>
             </GoogleReCaptchaProvider>
         }
      />

      <Route
            path={AUTH_GROUP_ROUTES.LINK_PARTNER_CREATING_ACCOUNT}
            element={<CreatingAccount />}
      />

      <Route
            path={AUTH_GROUP_ROUTES.LINK_PARTNER_STEP_1}
            element={<Step1 />}
      />

      <Route
            path={AUTH_GROUP_ROUTES.LINK_PARTNER_STEP_2}
            element={<Step2 />}
      />

        <Route
            path={AUTH_GROUP_ROUTES.LINK_PARTNER_VERIFICATION_IN_PROGRESS}
            element={<VerificationInProgress />}
        />

      <Route
        path={AUTH_GROUP_ROUTES.LINK_LOG_OUT}
        element={<Logout/>}
      />

      <Route
        element={<Navigate to={AUTH_GROUP_LINKS.LINK_LOGIN} replace/>}
      />
    </Routes>
  );
}
