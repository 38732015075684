import React, { useCallback, useEffect, useState } from "react";
import { useLocationQuery, useQueryString } from "../../../../base/hooks/useQueryString";
import Icon from "../../../../base/components/Icon";
import TableSearch from "../../../../base/components/Table/tableSearch";
import Table from "../../../../base/components/Table";
import {
  parseSorting,
  useLocationSource,
  usePaginationProvider,
  useSearchProvider, useSortProvider
} from "../../../../base/components/Table/hooks";
import { DEFAULT_DEBOUNCE_DELAY } from "../../../../base/constants/shared";
import { useService } from "../../../../base/hooks/useService";
import { useLocation, useParams, useHistory } from "react-router-dom";
import { useDebounce } from "../../../../base/hooks/useDebounce";
import { useLoading } from "../../../../base/hooks/useLoading";
import { LIMIT_OPTIONS } from "../../../../base/constants/pagination";
import UserAttributesService from "../../../../services/UserAttributes";
import { BUTTON_COLORS } from "../../../../base/components/Button/appearance";
import { RECOMMENDATIONS_LINKS } from "../../Recommendations/config";
import Button from "../../../../base/components/Button";
import { CreateUserAttribute } from "./CreateUserAttribute";
import ConfirmPopup from "../../../../base/components/ConfirmPopup";
import toastService from "../../../../services/ToastService";
import ToastService from "../../../../services/ToastService";
import { saveAs } from 'file-saver';

const ATTRIBUTES_ACTIONS = {
  DELETE: 'delete',
  EDIT: 'edit'
}

const attributeTypes = [undefined, 'Term', 'True or false', 'Number', 'Number range'];

const columns = [
  {
    Header: '#',
    width: 30,
    className: "bg-white",
    Cell: ({ row: { index } }) => {
      const {
        params: { offset = 0 },
      } = useLocationQuery();
      return <label className="mb-0">{index + 1 + (offset ?? 0)}</label>;
    },
  },
  {
    Header: 'Label',
    accessor: 'label',
    canSort: true,
    className: "bg-white",
    width: 190,
    Cell: ({ value }) => {
      return (
        <section className="w-fit-content d-flex align-items-center">
          {value}
        </section>
      );
    },
  },
  {
    Header: 'Name',
    accessor: 'name',
    canSort: true,
    className: "bg-white",
    width: 170,
    Cell: ({ value }) => {
      return <div className="w-100 text-truncate">{value}</div>;
    },
  },
  {
    Header: 'Category',
    accessor: 'category',
    canSort: true,
    className: "bg-white",
    width: 85,
    Cell: ({ value }) => {
      return <div className="w-100 text-truncate">{value}</div>;
    },
  },
  {
    Header: 'Value type',
    accessor: 'valueType',
    className: "bg-white",
    width: 75,
    Cell: ({ value }) => {
      return <div className="w-100 text-truncate">{attributeTypes[+value]}</div>;
    },
  },
  {
    Header: 'Value',
    accessor: 'value',
    width: 70,
    className: "bg-white",
    Cell: ({ value }) => {
      return <div className="w-100 text-truncate">{`${value}`}</div>;
    },
  },
  {
    Header: "Actions",
    className: "bg-white",
    width: 70,
    Cell: ({ row: { original }, actions }) => {
      
      return <section
        className="d-flex gap-1 align-items-center"
        onClick={(event) => {
          event.stopPropagation();
        }}
      >
        <Icon icon="edit"
          width={20}
          height={20}
          className="me-4 cursor-pointer impacts-section__view"
          onClick={(event) => {
            event.stopPropagation();
            actions.editAttribute(original);
          }}
        />
        
        <Icon
          icon={"trashIcon"}
          width={20}
          height={20}
          onClick={(event) => {
            event.stopPropagation();
            actions.deleteAttribute(original.id);
          }}
          className="me-4 cursor-pointer"
        />
      </section>;
    }
  }
];

const SearchPlaceholder = () => (
  <>
    No attributes for now.
  </>
);

const TableHeader = ({ searchProvider, headerActions }) => {
  const { id: userId } = useParams();
  /**
   * @type {UserAttributesService}
   */
  const userAttributesService = useService(UserAttributesService);

  const handleDownload = async () => {
    const result = await userAttributesService.downloadUserAttributeCSV(userId);
    const resultBlob = new Blob([result], { type: 'text/csv' });
    saveAs(resultBlob, `user-${userId}-attributes.csv`);
  }

  return (
    <section className="d-flex mb-3 align-items-center justify-content-between">
      <section className="d-flex align-items-center justify-content-between">
        <TableSearch
          className="biomarkers-search me-3"
          search={searchProvider.getValue()}
          onSearch={searchProvider.setValue}
          placeholder="Search attributes..."
        />
      </section>
      <section>
        <Button
          className="btn-download-csv me-2 cursor-pointer"
          color={BUTTON_COLORS.primaryOutline}
          onClick={() => {
            handleDownload();
          }}>
          <Icon className="me-2" icon="download"/>
          Download CSV file
        </Button>
        <Button
          color={BUTTON_COLORS.primary}
          onClick={headerActions.openCreateAttributePopup}
          className="px-3"
        >
          <span className="me-2">+</span>
          Add attribute
        </Button>
      </section>
    </section>
  );
};

export default function AttributesList() {
  /**
   * @type {UserAttributesService}
   */
  const userAttributesService = useService(UserAttributesService);
  /**
   * @type {ToastService}
   */
  const toastService = useService(ToastService);

  const { id } = useParams();
  const { search: locationSearch } = useLocation();

  const [collapsedRows, updateCollapsedRows] = useState([]);
  const [attributes, setAttributes] = useState([]);
  const [resultsPagination, setResultsPagination] = useState([]);
  
  const locationSource = useLocationSource();
  const [isTableLoading, { registerPromise: registerTablePromise }] = useLoading(true);
  const [attributePopupOpen, updateAttributePopupOpen] = useState(false);
  const [editAttribute, updateEditAttribute] = useState(null);
  const [attributeDelete, updateAttributeDelete] = useState(null);
  
  const {
    params: {
      search,
      limit = LIMIT_OPTIONS[0].value,
      offset = 0,
      label,
      name,
      category
    }
  } = useQueryString(locationSearch)
  
  const paginationProvider = usePaginationProvider({
    source: locationSource,
    alias: "offset",
    scope: "",
    fallback: 0
  });
  
  const limitProvider = usePaginationProvider({
    source: locationSource,
    alias: "limit",
    scope: "",
    fallback: 15,
    onApplyClearScope: ["offset"]
  });
  
  const searchProvider = useSearchProvider({
    source: locationSource,
    scope: "",
    alias: 'search',
    onApplyClearScope: [""]
  });
  
  const [debouncedSearch] = useDebounce(search, DEFAULT_DEBOUNCE_DELAY, () => paginationProvider.setValue(0));
  
  const fetchTable = useCallback(
    () => {
      const sortObject = parseSorting({ label, name, category });
      const params = {
        limit,
        offset,
        query: debouncedSearch,
        ...sortObject
      };
      return registerTablePromise(
        userAttributesService
          .getAttributesList(id, params)
          .then(({ data, pagination }) => {
            setAttributes(data);
            setResultsPagination(pagination);
          })
      );
    },
    [
      id,
      offset,
      limit,
      label,
      name,
      category,
      debouncedSearch
    ],
  )
  
  const generateSortProviderParams = (name) => {
    return {
      source: locationSource,
      alias: name,
      scope: "",
      onApplyClearScope: ["label", "name", "category"].filter(paramName => paramName !== name)
    }
  }
  
  const labelSortProvider = useSortProvider(generateSortProviderParams("label"));
  const nameSortProvider = useSortProvider(generateSortProviderParams("name"));
  const categorySortProvider = useSortProvider(generateSortProviderParams("category"));
  
  const toggleCollapse = (id) => {
    if (collapsedRows.includes(id)) {
      updateCollapsedRows(prevState => prevState.filter(item => item !== id))
      return
    }
    updateCollapsedRows(prevState => [...prevState, id]);
  }
  
  const openCreateAttributePopup = () => updateAttributePopupOpen(true);

  const openEditAttributePopup = (attribute) => {
    updateEditAttribute(attribute)
    openCreateAttributePopup()
  }
  
  const deleteAttribute = () => {
    userAttributesService
      .deleteUserAttribute(attributeDelete)
      .then(() => {
        toastService.success("Attribute has been successfully unassigned");
        fetchTable();
      })
  }
  
  useEffect(() => {
    fetchTable();
  }, [fetchTable]);
  
  return (
    <>
      <Table
        columns={columns}
        data={attributes}
        loading={isTableLoading}
        HeaderComponent={TableHeader}
        totalCount={resultsPagination.totalCount}
        headerActions={{ openCreateAttributePopup }}
        searchProvider={searchProvider}
        sortProviders={{
          ['label']: labelSortProvider,
          ['name']: nameSortProvider,
          ['category']: categorySortProvider,
        }}
        actions={{
          editAttribute: openEditAttributePopup,
          deleteAttribute: updateAttributeDelete
        }}
    
        limit={limitProvider.getValue()}
        limitProvider={limitProvider}
        offset={offset}
        paginationProvider={paginationProvider}
        commonPlaceholder="No attributes for now"
        placeholderForSearch={<SearchPlaceholder/>}
        withLocation
        collapsedState={collapsedRows}
        toggleCollapse={toggleCollapse}
        isLimitEditable={true}
        limitOptions={LIMIT_OPTIONS}
      />
      
      <CreateUserAttribute
        isOpen={attributePopupOpen}
        editingAttribute={editAttribute}
        close={() => {
          updateAttributePopupOpen(false)
          updateEditAttribute(null)
        }}
        afterSubmit={fetchTable}
      />
  
      {attributeDelete ? (
        <ConfirmPopup
          isOpen={!!attributeDelete}
          updateIsOpen={updateAttributeDelete}
          onSubmit={deleteAttribute}
          title="Delete attribute"
          description="Are you sure you want to delete the chosen attribute?"
          submitBtnText="Delete"
        />
      ) : null}
    </>
  )
}