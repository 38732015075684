import React from "react";
import AuthPartnerLayout from "../../../components/AuthPartnerLayout";
import logo from "../../../../../assets/images/partners/timer-new.svg";
import {KEY_USER} from "../../../../../base/constants/storage";
import {useService} from "../../../../../base/hooks/useService";
import StorageService from "../../../../../services/StorageService";

const VerificationInProgress = () => {
    /**
     * @type {StorageService}
     */
    const storage = useService(StorageService);
    const { email = "" } = storage.get(KEY_USER, { email: "" });

    return(
        <AuthPartnerLayout>
            <section
                className="partner-creating-account-section h-100 w-full d-flex flex-column align-items-center justify-content-center"
            >
                <div className="partner-step-layout align-items-center justify-content-center mt-0">
                    <div className="d-flex gap-3">
                        <img className="partner-creating-account-logo" src={logo} alt="logo" />
                        <h1 className="partner-creating-account-title w-full text-center">
                            Verification in progress
                        </h1>
                    </div>
                    <p className="partner-text-grey font-size-18 mt-2 mb-2 text-center">
                        Thanks for submitting your documents! Our team is reviewing them to ensure everything is in order.
                    </p>
                    <p className="partner-text-grey font-size-18 mb-2 text-center">
                        If everything’s good, you’ll get an activation email. If we need more info, we’ll let you know. We’ll keep you updated during 24 hours on this email :
                    </p>
                    <p className="font-size-20 font-weight-semibold mb-2 partner-text-black">
                        {email}
                    </p>
                    <div className="d-flex gap-2">
                        <p className="partner-text-grey font-size-16 mb-0">
                            Do you need help?
                        </p>
                        <a
                            href="mailto:support@optimallyme.com"
                            className="partner-sign-up-link link font-size-16 font-weight-semibold"
                        >
                            Contact support
                        </a>
                    </div>
                </div>
            </section>
        </AuthPartnerLayout>
    )
}

export default VerificationInProgress