import React, {useCallback, useEffect, useRef, useState} from "react";
import {useGoogleReCaptcha} from "react-google-recaptcha-v3";
import { Formik } from "formik";

import AuthPartnerLayout from "../../../components/AuthPartnerLayout";
import GoogleCaptchaLinks from "../../../components/GoogleCaptchaLinks";
import {useLocationQuery} from "../../../../../base/hooks/useQueryString";
import {validationSchema} from "./form";
import {MAX_EMAIL_LENGTH, MAX_NAME_LENGTH} from "../../../../../validation/lengthConstants";
import FormikInput from "../../../../../base/components/FormikInput";
import {useLoading} from "../../../../../base/hooks/useLoading";
import AuthService from "../../../../../services/AuthService";
import {useService} from "../../../../../base/hooks/useService";
import Checkbox from "../../../../../base/components/Checkbox";
import {Link, useNavigate} from "react-router-dom";
import ConfigService from "../../../../../services/ConfigService";
import Icon from "../../../../../base/components/Icon";
import {AUTH_GROUP_LINKS} from "../../../config";
import Button from "../../../../../base/components/Button";
import {BUTTON_COLORS} from "../../../../../base/components/Button/appearance";
import PartnersService from "../../../../../services/PartnersService";
import {SpecialisationsDropdown} from "./SpecialisationsDropdown/SpecialisationsDropdown";
import FormikPhoneInputField from "../../../../../base/components/FormikPhoneNumberInput";
import {KEY_USER} from "../../../../../base/constants/storage";
import StorageService from "../../../../../services/StorageService";
import SessionStorage from "../../../../../services/SessionStorage";

export const removeSpaces = (value = "") => value.replace(" ", "");
const initialValues = {
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    specialisationsIds: []
}

const initialLinksValues = {
    terms: '',
    privacy: ''
}

const LINKS_KEYS = {
    terms: 'TERMS_OF_USE_LINK',
    privacy: 'PRIVACY_POLICY_LINK'
}

const DEFAULT_OPTIMALLY_ME_ORG = 1;

const SignUp = () => {
    /**
     * @type {AuthService}
     */
    const authService = useService(AuthService);
    /**
     * @type {ConfigService}
     */
    const configService = useService(ConfigService);
    /**
     * @type {PartnersService}
     */
    const partnersService = useService(PartnersService);
    /**
     * @type {StorageService}
     */
    const storage = useService(StorageService);
    /**
     * @type {SessionStorage}
     */
    const storageSession = useService(SessionStorage);

    const navigate = useNavigate();

    const {params: {organisationId}} = useLocationQuery();
    const formikRef = useRef();

    const {executeRecaptcha} = useGoogleReCaptcha();
    const [captchaError, setCaptchaError] = useState(false);
    const [requestError, setRequestError] = useState(false);
    const [agreedWithPolicy, updateAgreedWithPolicy] = useState(false);
    const [links, updateLinks] = useState(initialLinksValues);

    const [isLoading, { registerPromise }] = useLoading();

    const getConfig = useCallback(() => {
        configService.fetchFrontendConfig()
            .then(({ data }) => {
                if (!data) return

                const linksResults = data.filter(
                    (element) => element?.variable === LINKS_KEYS.terms || element?.variable === LINKS_KEYS.privacy,
                )

                updateLinks({
                    terms: linksResults.find((link) => link.variable === LINKS_KEYS.terms)?.value || "",
                    privacy: linksResults.find((link) => link.variable === LINKS_KEYS.privacy)?.value || "",
                })
            })
            .catch((error) => {
                console.error("Failed to fetch config:", error)
            })
    }, [configService, updateLinks])

    const handleReCaptchaVerify = useCallback(async () => {
        if (!executeRecaptcha) {
            throw new Error("Recaptcha not available yet");
        }

        const token = await executeRecaptcha("signup");
        return registerPromise(
            authService.validateCaptchaToken(token).catch(err => {
                throw err;
            })
        );
    }, [executeRecaptcha]);

    const registerPartner = useCallback(
        ({ email, firstName, lastName, organisationId, specialisationsIds, phone }) => {
            setCaptchaError(false);
            handleReCaptchaVerify()
                .then(() => {
                    registerPromise(
                        partnersService
                            .registerPartner({
                                email,
                                organisationId: organisationId || DEFAULT_OPTIMALLY_ME_ORG,
                                firstName,
                                lastName,
                                specialisationsIds,
                                phone: `+${phone}`
                            })
                            .then(data => {
                                //TODO Save results to the Storage
                                const { session, practitioner } = data;
                                storage.set(KEY_USER, practitioner);
                                storageSession.setSession(session);
                                navigate(AUTH_GROUP_LINKS.LINK_PARTNER_CREATING_ACCOUNT)
                            })
                            .catch(() => {
                                setRequestError(true);
                            })
                    );
                })
                .catch(() => {
                    setCaptchaError(true);
                });
        },
        [
            handleReCaptchaVerify,
            organisationId,
        ]
    );

    useEffect(() => {
        getConfig()
    }, [])

    return (
            <AuthPartnerLayout>
                <section
                    className="partner-sign-up-section w-full d-flex justify-content-center"
                >
                    <h1 className="partner-sign-up-title">
                        Transform your health practice with OptimallyMe
                    </h1>
                    <div className="partner-sign-up-form">
                        {captchaError &&
                        <div className="partner-sign-up-recaptcha-error text-white mb-4 d-flex align-items-center">
                            <Icon icon="infoCircleWarning" className="me-3" />
                            Recaptcha encountered an error. Please, try again.
                        </div>
                        }
                        <Formik
                            initialValues={initialValues}
                            validationSchema={validationSchema}
                            enableReinitialize
                            validateOnBlur
                            validateOnMount
                            innerRef={r => {
                                formikRef.current = r;
                            }}
                            onSubmit={registerPartner}
                        >
                            {({ errors, handleSubmit }) => {
                                return (
                                    <form
                                        className="xl:w-authForm mt-8 xl:mt-0 md:mt-8 "
                                        onSubmit={handleSubmit}
                                    >
                                        <section className="d-flex gap-3">
                                            <FormikInput
                                                id="firstName"
                                                name="firstName"
                                                label="First name"
                                                containerClassName="relative w-100"
                                                placeholder="Enter your first name"
                                                type="text"
                                                withReset
                                                afterOnChange={() => setRequestError(false)}
                                                maxLength={MAX_NAME_LENGTH}
                                            />
                                            <FormikInput
                                                id="lastName"
                                                name="lastName"
                                                label="Last name"
                                                containerClassName="relative w-100"
                                                placeholder="Enter your last name"
                                                type="text"
                                                withReset
                                                afterOnChange={() => setRequestError(false)}
                                                maxLength={MAX_NAME_LENGTH}
                                            />
                                        </section>
                                        <FormikInput
                                            id="email"
                                            name="email"
                                            label="Email"
                                            containerClassName="relative  mt-4"
                                            placeholder="Enter your email"
                                            type="email"
                                            withReset
                                            afterOnChange={() => setRequestError(false)}
                                            maxLength={MAX_EMAIL_LENGTH}
                                            format={removeSpaces}
                                        />

                                        <div className="mt-4">
                                            <FormikPhoneInputField
                                                label="Phone number"
                                                name="phone"
                                                afterOnChange={() => setRequestError(false)}
                                            />
                                        </div>

                                        <div className="mt-4">
                                            <SpecialisationsDropdown
                                                label="Specialisation(s)"
                                                placeholder="Start typing specialisation and select all that apply..."
                                                name="specialisationsIds"
                                                afterOnChange={() => setRequestError(false)}
                                            />
                                        </div>

                                        <section className="mt-4-5 d-flex align-items-center partner-text-grey">
                                            <Checkbox
                                                className="checked:!bg-black"
                                                value={agreedWithPolicy}
                                                onChange={() =>
                                                    updateAgreedWithPolicy(prevState => !prevState)
                                                }
                                            />
                                            I agree to the
                                            <a
                                                href={links.privacy}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                className="partner-sign-up-link link mx-1"
                                            >
                                                Privacy Policy
                                            </a>
                                            and
                                            <a
                                                href={links.terms}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                className="partner-sign-up-link link mx-1 pl-8 xl:pl-0 md:pl-0"
                                            >
                                                Terms and Conditions
                                            </a>
                                        </section>

                                        <Button
                                            color={BUTTON_COLORS.primary}
                                            type="submit"
                                            loading={isLoading}
                                            className="sign-up-submit mt-4-5 w-100"
                                            disabled={
                                                isLoading ||
                                                !!Object.keys(errors).length ||
                                                requestError ||
                                                !agreedWithPolicy
                                            }
                                        >
                                            Create account
                                        </Button>

                                        <div className="mt-3 text-center text-default partner-text-grey">
                                            Are you a member?
                                            <Link
                                                to={AUTH_GROUP_LINKS.LINK_LOGIN}
                                                className="partner-sign-up-link link ms-2"
                                            >
                                                Sign in
                                            </Link>
                                        </div>
                                    </form>
                                )
                            }}
                        </Formik>
                    </div>
                </section>
                <div
                    className="w-100 d-flex align-items-center justify-content-center">
                    <GoogleCaptchaLinks/>
                </div>
            </AuthPartnerLayout>
    )
}

export default SignUp;