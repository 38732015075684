import React from 'react';
import DailyCaloriesWidget from "./DailyCalories/DailyCaloriesWidget";
import WaterIntakeWidget from "./WaterIntake/WaterIntakeWidget";
import DeviceDataWidget from "./DeviceData/DeviceDataWidget";
import MirrorMirrorMindWidget from "./MirrorMirrorMind/MirrorMirrorMindWidget";
import PropTypes from "prop-types";
import MirrorMirrorBodyWidget from "./MirrorMirrorBody/MirrorMirrorBodyWidget";
import FastingWidget from "./Fasting/FastingWidget";
import MoodWidget from "./Mood/MoodWidget";
import TopNavBar from "./TopNavBar/TopNavBar";
import classes from "./MobileAppPreview.module.scss";
import {MOBILE_WIDGET_IDS} from "../constants";
import classNames from "classnames";

const createWidgetComponents = (primaryColor = "", secondaryColor = "") => ({
    [MOBILE_WIDGET_IDS.dailyProgress]: <DailyCaloriesWidget key={MOBILE_WIDGET_IDS.dailyProgress} primaryColor={primaryColor} secondaryColor={secondaryColor} />,
    [MOBILE_WIDGET_IDS.waterIntake]: <WaterIntakeWidget key={MOBILE_WIDGET_IDS.waterIntake} primaryColor={primaryColor} />,
    [MOBILE_WIDGET_IDS.deviceData]: <DeviceDataWidget key={MOBILE_WIDGET_IDS.deviceData}  primaryColor={primaryColor} secondaryColor={secondaryColor} />,
    [MOBILE_WIDGET_IDS.mirrorMirrorMind]: <MirrorMirrorMindWidget key={MOBILE_WIDGET_IDS.mirrorMirrorMind}  primaryColor={primaryColor} />,
    [MOBILE_WIDGET_IDS.mirrorMirrorBody]: <MirrorMirrorBodyWidget key={MOBILE_WIDGET_IDS.mirrorMirrorBody}  primaryColor={primaryColor} />,
    [MOBILE_WIDGET_IDS.fasting]: <FastingWidget key={MOBILE_WIDGET_IDS.fasting}  primaryColor={primaryColor}  />,
    [MOBILE_WIDGET_IDS.mood]: <MoodWidget key={MOBILE_WIDGET_IDS.mood}  primaryColor={primaryColor} secondaryColor={secondaryColor} />
})

export default function NutritionalDashboard({
                                                 primaryColor = "",
                                                 secondaryColor = "",
                                                 widgets = [],
                                                 hasTopOffset = false
}) {
  const widgetComponents = createWidgetComponents(primaryColor, secondaryColor)
  return (
      <div className="position-relative">
        <div className={classes.dynamicIsland} />
        <TopNavBar />
        <div className={classNames(hasTopOffset ? classes.dynamicBlock : "")}>
            {widgets?.map((widget) => {
                const WidgetComponent = widgetComponents[widget.id]
                return (
                    <div
                        key={widget.id}
                        className={widget.id === MOBILE_WIDGET_IDS.dailyProgress && widget.order !== 1 ? "mt-3" : undefined}
                    >
                        {WidgetComponent}
                    </div>
                )
            })}
        </div>
      </div>
  )
}

NutritionalDashboard.propTypes = {
 primaryColor: PropTypes.string,
 secondaryColor: PropTypes.string,
}