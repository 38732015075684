import yup from "../../../../../../validation/yup";
import {
    FILES_MAX_COUNT, MAX_CREDENTIALS_COUNT,
    NAME_MAX_LENGTH,
    NAME_MIN_LENGTH,
    REFERENCE_NUMBER_MAX_LENGTH,
    REQUIRED_MESSAGE
} from "./constants";
import {generateInvalidMessage, generateMinInvalidMessage} from "../../../../../../validation/user";

const referenceNumberRegExp = /^[0-9]*$/;

export const validationSchema = yup.object().shape({
    credentials: yup.array().of(
        yup.object().shape({
            name: yup.string()
                .min(NAME_MIN_LENGTH, generateMinInvalidMessage('License/certification/membership name', NAME_MIN_LENGTH))
                .max(NAME_MAX_LENGTH)
                .required(REQUIRED_MESSAGE),
            issuingOrganisation: yup.string()
                .min(NAME_MIN_LENGTH, generateMinInvalidMessage('Issuing organization ', NAME_MIN_LENGTH))
                .max(NAME_MAX_LENGTH)
                .required(REQUIRED_MESSAGE),
            referenceNumber: yup.string()
                .matches(referenceNumberRegExp, generateInvalidMessage("ID or reference number"))
                .trim()
                .max(REFERENCE_NUMBER_MAX_LENGTH),
            issueDate: yup.string()
                .nullable()
                .test(
                    'is-before-today',
                    'Issue date must be before today',
                    (value) => {
                        if (!value) return true;
                        const issueDate = new Date(value);
                        const today = new Date();
                        today.setHours(0, 0, 0, 0);
                        return issueDate < today;
                    }
                )
                .required(REQUIRED_MESSAGE),
            doesntExpire: yup.boolean(),
            expirationDate: yup.string()
                .when('doesntExpire', {
                    is: false,
                    then: yup.string().nullable().required(REQUIRED_MESSAGE),
                    otherwise: yup.string().nullable(),
                }),
            files: yup.array()
                .nullable()
                .min(1)
                .max(FILES_MAX_COUNT, `You can select up to ${FILES_MAX_COUNT} files`),
        })
    )
    .min(1)
    .max(MAX_CREDENTIALS_COUNT)
})