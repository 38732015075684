import React, {useCallback, useEffect, useMemo, useState} from "react";
import BaseLayoutWithCard from "../../../../base/components/BaseLayoutWithCard";
import {useService} from "../../../../base/hooks/useService";
import ToasterService from "../../../../services/ToastService";
import PartnersService from "../../../../services/PartnersService";
import {useLoading} from "../../../../base/hooks/useLoading";
import {useLocation} from "react-router-dom";
import {useQueryString} from "../../../../base/hooks/useQueryString";
import {
    useFilterProvider,
    useLocationSource,
    usePaginationProvider,
    useSearchProvider, useSortProvider
} from "../../../../base/components/Table/hooks";
import Table from "../../../../base/components/Table";
import {LIMIT_OPTIONS} from "../../../../base/constants/pagination";
import {NoPractitionersPlaceholder, SearchPlaceholder, columns, TableHeader} from "./components";
import InvitePractitionerModalForm from "./InvitePractitionerModalForm";
import PractitionersDetailsModal from "./PractitionersDetailsModal";

const breadcrumbs = {
    title: "Practitioners Management", breadcrumbItems: []
};

const DEFAULT_TABLE_LIMIT = 15;

const PractitionersList = () => {
    /**
     * @type {PartnersService}
     */
    const partnersService = useService(PartnersService);
    /**
     * @type {ToasterService}
     */
    const toastService = useService(ToasterService);

    const [isLoading, { registerPromise }] = useLoading(true);
    const { search: locationSearch } = useLocation();
    const {
        params: {
            limit = DEFAULT_TABLE_LIMIT,
            offset = 0,
            search,
            name,
            email,
            status,
            submissionDate,
            statusFilter,
            specialisationFilter
        }
    } = useQueryString(locationSearch);

    const [practitioners, updatePractitioners] = useState([]);
    const [practitionersPagination, updatePractitionersPagination] = useState({});
    const [showFormPopup, updateShowFormPopup] = useState(false);
    const [isOpenDetailsPopup, updateIsOpenDetailsPopup] = useState(false);

    const locationSource = useLocationSource();

    const onClickInvite = () => {
        updateShowFormPopup(true)
    }

    const updatePractitionerStatus = (id, status) => {
        partnersService.changePartnerStatus(id, {status})
            .then(() => {
                getPractitionersList()
                toastService.success("The practitioners status has been successfully changed")
            })
            .catch((error) => {
                toastService.error(error.message)
            })
    }

    const paginationProvider = usePaginationProvider({
        source: locationSource,
        alias: "offset",
        scope: "",
        fallback: 0
    });

    const limitProvider = usePaginationProvider({
        source: locationSource,
        alias: "limit",
        scope: "",
        fallback: DEFAULT_TABLE_LIMIT,
        onApplyClearScope: ["offset"]
    });

    const searchProvider = useSearchProvider({
        source: locationSource,
        scope: "",
        alias: 'search',
        onApplyClearScope: [""]
    });

    const statusFilterProvider = useFilterProvider({
        source: locationSource,
        scope: "",
        alias: 'statusFilter',
        onApplyClearScope: ["offset"]
    });

    const specialisationFilterProvider = useFilterProvider({
        source: locationSource,
        scope: "",
        alias: 'specialisationFilter',
        onApplyClearScope: ["offset"]
    });

    const filterProvider = useMemo(() => ({
        specialisationsIds: specialisationFilterProvider,
        statuses: statusFilterProvider
    }), [specialisationFilterProvider, statusFilterProvider]);

    const sortKeys = ["name", "email", "status", "submissionDate"];
    const getSortScope = (key) => [...sortKeys.filter(item => item !== key), "offset"];

    const nameSortProvider = useSortProvider({
        source: locationSource,
        alias: "name",
        scope: "",
        onApplyClearScope: getSortScope("name")
    });

    const emailSortProvider = useSortProvider({
        source: locationSource,
        alias: "email",
        scope: "",
        onApplyClearScope: getSortScope("email")
    });

    const statusSortProvider = useSortProvider({
        source: locationSource,
        alias: "status",
        scope: "",
        onApplyClearScope: getSortScope("status")
    });

    const submissionDateSortProvider = useSortProvider({
        source: locationSource,
        alias: "submissionDate",
        scope: "",
        onApplyClearScope: getSortScope("submissionDate")
    });

    const getPractitionersList = useCallback(() => {
        const [orderBy, orderType] = Object.entries({
            name,
            email,
            status,
            submissionDate
        }).find(([_, value]) => value) || [];

        registerPromise(partnersService.getPartners({
            limit,
            offset,
            orderBy,
            orderType,
            query: search,
            statuses: statusFilter,
            specialisationsIds: specialisationFilter
        }).then(({ data, pagination }) => {
            updatePractitioners(data);
            updatePractitionersPagination(pagination);
        }));
    }, [limit, offset, name, email, status, submissionDate, search, statusFilter, specialisationFilter]);

    useEffect(() => {
        getPractitionersList();
    }, [getPractitionersList]);

    return(
        <>
            <BaseLayoutWithCard breadcrumbs={breadcrumbs} >
                <Table
                    columns={practitioners.length ? columns : []}
                    data={practitioners}
                    loading={isLoading}
                    HeaderComponent={TableHeader}
                    totalCount={practitionersPagination.totalCount}
                    limit={limitProvider.getValue()}
                    offset={offset}
                    paginationProvider={paginationProvider}
                    filterProvider={filterProvider}
                    hasActiveFilters={!!specialisationFilterProvider.getValue() || !!statusFilterProvider.getValue()}
                    searchProvider={searchProvider}
                    commonPlaceholder={<NoPractitionersPlaceholder/>}
                    placeholderForSearch={<SearchPlaceholder/>}
                    isRowClick
                    rowClickHandler={(row) => {
                        updateIsOpenDetailsPopup(row)
                    }}
                    actions={{
                        view: (row) => updateIsOpenDetailsPopup(row),
                        approve: (id, status) => {
                            updatePractitionerStatus(id, status)
                        },
                        waitlist: (id, status) => {
                            updatePractitionerStatus(id, status)
                        },
                        reject: (id, status) => {
                            updatePractitionerStatus(id, status)
                        },
                        deletePractitioner: (id) => {
                            console.log('delete', id)
                        }
                    }}
                    sortProviders={{
                        name: nameSortProvider,
                        email: emailSortProvider,
                        status: statusSortProvider,
                        submissionDate: submissionDateSortProvider
                    }}
                    isLimitEditable
                    limitProvider={limitProvider}
                    limitOptions={LIMIT_OPTIONS}
                    tableClassName="overflow-x-visible"
                    headerActions={{ onClickInvite }}
                />
                {showFormPopup && <InvitePractitionerModalForm
                    isOpen={showFormPopup}
                    onClose={() => {
                        updateShowFormPopup(false)
                    }}
                />}

                {isOpenDetailsPopup && <PractitionersDetailsModal
                    isOpen={isOpenDetailsPopup}
                    updateIsOpen={updateIsOpenDetailsPopup}
                    row={isOpenDetailsPopup}
                    afterOnClose={() => getPractitionersList()}
                />}
            </BaseLayoutWithCard>
        </>
    )
}

export default PractitionersList