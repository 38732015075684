import {
    generateInvalidMessage,
    generateMinInvalidMessage,
    generateRequiredMessage,
    USER_EMAIL
} from "../../../../../validation/user";
import yup from "../../../../../validation/yup";
import {MIN_NAME_LENGTH, PHONE_LENGTH} from "../../../../../validation/lengthConstants";

export const removePhoneMask = (phone) => phone?.replace(/[\s_+]/g, "");
const phoneRegExp = /^(?:0|\+?447)(?:\d\s?){9,10}$/
const nameRegExp = /^(?=.*[A-Z]?)(?=.*[a-z]?)(?=.*[-]?)\S.*$/

export const validationSchema = yup.object().shape({
    email: USER_EMAIL.required(),
    firstName: yup
        .string()
        .min(MIN_NAME_LENGTH, generateMinInvalidMessage("First name", MIN_NAME_LENGTH))
        .matches(nameRegExp, generateInvalidMessage("first name"))
        .trim()
        .required(generateRequiredMessage("First name")),
    lastName: yup
        .string()
        .min(MIN_NAME_LENGTH, generateMinInvalidMessage("Last name", MIN_NAME_LENGTH))
        .matches(nameRegExp, generateInvalidMessage("last name"))
        .trim()
        .required(generateRequiredMessage("Last name")),
    phone: yup
        .string()
        .test("phone-length", `Must be ${PHONE_LENGTH} digits`, (value) => {
            const unmaskedValue = removePhoneMask(value);
            return unmaskedValue?.length === (PHONE_LENGTH) || unmaskedValue?.length === 0;
        })
        .matches(phoneRegExp, 'Invalid phone number. Please review and enter correct number')
        .required(generateRequiredMessage("Phone")),
    specialisationsIds: yup
        .array()
        .of(yup.number())
        .min(1, `Please select at least one option`)
        .max(3, `Specialisation(s) must contain not more than 3 elements`)
        .required(generateRequiredMessage("Specialisations")),
})