import {CustomModal, ModalActions, ModalBody, ModalHeader} from "../../../../../../../base/components/CustomModal";
import Button from "../../../../../../../base/components/Button";
import {BUTTON_COLORS} from "../../../../../../../base/components/Button/appearance";
import React from "react";
import {
    findAllergens,
    findAllRangeValues,
    findComplexity,
    findNIKcalValues,
    findReadyInTime
} from "../../../../helpers";
import {MEAL_TYPES_LABELS} from "../../../../../../../base/constants/foods";

export default function MealplanPlaygroundPopup({
                                            isOpen,
                                            onClose,
                                            recipes,
                                            details,
                                            mealTime
                                          }) {
    const filterData = details?.function_score?.query?.bool?.filter
    const mustData = details?.function_score?.query?.bool?.must
    const mustNotData = details?.function_score?.query?.bool?.must_not
    const shouldData = details?.function_score?.query?.bool?.should

    const readyInTime = findReadyInTime(filterData)
    const complexity = findComplexity(filterData)
    const kcals = findNIKcalValues(mustData)
    const allergens = findAllergens(mustNotData)
    const nutrients = findAllRangeValues(shouldData)

    return(
        <CustomModal isOpen={isOpen} size="xl" scrollable={true}>
            <ModalHeader onClose={onClose}>
                {`Mealplan playground logs for ${MEAL_TYPES_LABELS[mealTime]}`}
            </ModalHeader>
            <ModalBody>
                <p className="text-center font-weight-semibold">
                    Restrictions
                </p>
                <div className="d-flex gap-4 mb-4">
                    <div className="w-50">
                        {readyInTime?.lte && <div className="d-flex">
                            <p className="font-weight-semibold mb-0 me-2">
                                Preparation time:
                            </p>
                            <p className="mb-0">
                                {`< ${readyInTime?.lte}min`}
                            </p>
                        </div>}
                        {complexity?.lte && <div className="d-flex">
                            <p className="font-weight-semibold mb-0 me-2">
                                Count of ingredients:
                            </p>
                            <p className="mb-0">
                                {`< ${complexity?.lte}`}
                            </p>
                        </div>}
                        {!!kcals?.length && <div className="d-flex">
                            <p className="font-weight-semibold mb-0 me-2">
                                Kcal:
                            </p>
                            <p className="mb-0">
                                {kcals?.map((kcal) => {
                                    return `${kcal.gte}-${kcal.lte}`
                                }).join("; ")}
                            </p>
                        </div>}
                        <div className="d-flex">
                            <p className="font-weight-semibold mb-0 me-2">
                                Recipe status:
                            </p>
                            <p className="mb-0">
                                Ready to use
                            </p>
                        </div>
                        {!!allergens?.length && <div className="d-flex">
                            <p className="font-weight-semibold mb-0 me-2">
                                Exclude:
                            </p>
                            <p className="mb-0">
                                {`Allergens (${allergens.join(', ')})`}
                            </p>
                        </div>}
                    </div>
                    <div className="w-50">
                        {!!nutrients?.length && <div>
                            <p className="font-weight-semibold mb-0">
                                Nutrients:
                            </p>
                            {nutrients?.map((nutrient, index) => {
                                if ((nutrient?.boost && Number.isInteger(nutrient?.boost)) || !nutrient?.boost) {
                                    return <p key={index} className="mb-0">{`${nutrient.type}: ${nutrient.gte} - ${nutrient.lte}`}</p>
                                }
                            })}
                        </div>}
                    </div>
                </div>
                {!!recipes?.length && <div>
                    <p className="text-center font-weight-semibold">
                        {`Recipe${recipes?.length > 1 ? 's' : ''} found`}
                    </p>
                    {recipes?.map((recipe, index) => {
                        return <p className="mb-0" key={index}>{`${index + 1}) ${recipe.foodName} - ${recipe.kcal} Kcal${recipes?.length - 1 === index ? "." : ";"  }`} </p>
                    })}
                </div>}
            </ModalBody>
            <ModalActions>
                <Button color={BUTTON_COLORS.primaryOutline} onClick={onClose} className="mb-0">
                    Close
                </Button>
            </ModalActions>
        </CustomModal>
    )
}