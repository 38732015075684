import React, {useEffect, useState} from "react";
import AuthPartnerLayout from "../../../components/AuthPartnerLayout";
import GoogleCaptchaLinks from "../../../components/GoogleCaptchaLinks";

import logo from "../../../../../assets/images/partners/logo.svg";
import {useNavigate} from "react-router-dom";
import {AUTH_GROUP_LINKS} from "../../../config";

const MAX_DOTS_ELEMENTS = 3;
const DOTS_DURATION = 500;
const PAGE_DURATION = 3000;

const CreatingAccount = () => {
    const navigate = useNavigate();
    const [dotCount, setDotCount] = useState(0)

    useEffect(() => {
        const interval = setInterval(() => {
            setDotCount((prev) => (prev >= MAX_DOTS_ELEMENTS ? 0 : prev + 1))
        }, DOTS_DURATION)

        return () => clearInterval(interval)
    }, [])

    useEffect(() => {
        const redirectTimer = setTimeout(() => {
            return navigate(AUTH_GROUP_LINKS.LINK_PARTNER_STEP_1)
        }, PAGE_DURATION)

        return () => clearTimeout(redirectTimer)
    }, [])

    return(
        <AuthPartnerLayout>
            <section
                className="partner-creating-account-section h-100 w-full d-flex flex-column align-items-center justify-content-center"
            >
                <img className="partner-creating-account-logo" src={logo} alt="logo" />
                <h1 className="partner-creating-account-title w-full text-center mt-3 mb-3">
                    Creating your account
                </h1>
                <div className="text-center partner-text-grey font-size-18">
                    Please wait a moment{".".repeat(dotCount)}
                </div>
            </section>
            <div
                className="w-100 d-flex align-items-center justify-content-center">
                <GoogleCaptchaLinks/>
            </div>
        </AuthPartnerLayout>
    )
}

export default CreatingAccount