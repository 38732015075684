import React, {useCallback, useState} from "react";
import classNames from "classnames";

import {CustomModal, ModalActions, ModalBody, ModalHeader} from "../../../../../../../base/components/CustomModal";
import {BUTTON_COLORS} from "../../../../../../../base/components/Button/appearance";
import {
    ALLOWED_DOCUMENT_FILE_EXTENSION,
    DOCUMENT_FILE_MASK,
    ERROR_ALLOWED_DOCUMENT_FILE_TYPE_MESSAGE, ERROR_COUNT_DOCUMENT_FILE_TYPE_MESSAGE, ERROR_DOCUMENT_FILE_SIZE_MESSAGE,
    FILES_MAX_COUNT,
    MAX_DOCUMENT_FILE_SIZE,
    MAX_PARTNER_FILE_SIZE
} from "../constants";
import Button from "../../../../../../../base/components/Button";

import classes from "./UploadProffesionalDocuments.module.scss";
import {DropZoneCard} from "../../../../../../../base/components/Dropzone";

import Icon from "../../../../../../../base/components/Icon";
import {
    validateEmptyFile,
    validateFileSize,
    validateFileType
} from "../../../../../../../validation/fileUploadAndProcessing";
import {
    BYTES_IN_KILOBYTE,
    KILOBYTES_IN_MEGABYTE, PRACTITIONER_CREDENTIAL_FILE_TYPE, REACT_DROPZONE_INVALID_FILE_SIZE_ERROR,
    REACT_DROPZONE_INVALID_FILE_TYPE_ERROR,
} from "../../../../../../../base/constants/shared";
import {useService} from "../../../../../../../base/hooks/useService";
import ToasterService from "../../../../../../../services/ToastService";
import useUploadImages from "../../../../../../../base/components/Dropzone/useUploadImages";
import {Spinner} from "reactstrap";
import {formatBytes} from "../helpers";

const UploadProfessionalDocuments = ({
                                         isOpen,
                                         updateIsOpen,
                                         files,
                                         setFieldValue,
                                         setFieldTouched,
                                         name,
                                         index
                                     }) => {
    /**
     * @type {ToasterService}
     */
    const toastService = useService(ToasterService);

    const [errorMessage, setErrorMessage] = useState("");
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [isLoading, updateIsLoading] = useState(true);
    const [isUploading, setIsUploading] = useState();
    const uploadImage = useUploadImages();


    const onClose = () => {
        updateIsOpen((prevState) => ({
            ...prevState,
            [index]: false
        }));
    }

    const handleAcceptedFile = useCallback(async (file) => {
        setErrorMessage("");

        try {
            validateFileType(file, ALLOWED_DOCUMENT_FILE_EXTENSION, ERROR_ALLOWED_DOCUMENT_FILE_TYPE_MESSAGE);
            validateFileSize(file, MAX_DOCUMENT_FILE_SIZE, ERROR_DOCUMENT_FILE_SIZE_MESSAGE);
            validateEmptyFile(file);
        } catch ({message}) {
            toastService.error(message)
            setErrorMessage(message);
            return;
        }

        setSelectedFiles((prev) => {
            return [
                ...prev,
                {
                    ...file,
                    value: URL.createObjectURL(file),
                    fileData: file,
                    file: {
                        name: file.name,
                        bytes: file.size
                    }
                }]
        });

    }, [setErrorMessage]);

    const onDrop = useCallback(
        (acceptedFiles, fileRejections) => {
            if (fileRejections?.length > FILES_MAX_COUNT) {
                toastService.error(ERROR_COUNT_DOCUMENT_FILE_TYPE_MESSAGE);
            }
            fileRejections.forEach((file) => {
                file.errors.forEach((err) => {
                    if (err.code === REACT_DROPZONE_INVALID_FILE_TYPE_ERROR) {
                        toastService.error(ERROR_ALLOWED_DOCUMENT_FILE_TYPE_MESSAGE);
                    }
                    if (err.code === REACT_DROPZONE_INVALID_FILE_SIZE_ERROR) {
                        toastService.error(ERROR_DOCUMENT_FILE_SIZE_MESSAGE);
                    }

                });
            });

            if (!acceptedFiles.length) return;

            acceptedFiles.forEach((file) => handleAcceptedFile(file));

        },
        [handleAcceptedFile]
    );

    const deleteDocument = useCallback((indexDelete) => {
        const filteredFiles = selectedFiles.filter((_, i) => i !== indexDelete);
        setSelectedFiles(filteredFiles);
    }, [selectedFiles])

    const onUploadComplete = useCallback((uploadedFiles) => {
        const transformedUploadedFiles = uploadedFiles.map((uploadedFile) => uploadedFile.file)

        setFieldTouched(name, true, true)
        setFieldValue(name, transformedUploadedFiles)
        onClose()
    }, [index, name, setFieldValue, setFieldTouched])

    const onConfirm = useCallback(async () => {
        if (selectedFiles.length === 0) return

        updateIsLoading(true)
        const uploadedFiles = []
        const controller = new AbortController();
        const abortSignal = controller.signal;

        try {
            const uploadPromises = selectedFiles.map((file, index) => {
                setIsUploading((prev) => ({
                    ...prev,
                    [index]: true,
                }))

                return uploadImage(file.fileData, PRACTITIONER_CREDENTIAL_FILE_TYPE, abortSignal, file.file.name)
                    .then((data) => {
                        uploadedFiles.push(data)
                        return data
                    })
                    .finally(() => {
                        setIsUploading((prev) => ({
                            ...prev,
                            [index]: false,
                        }))
                    })
            })

            await Promise.all(uploadPromises)

            onUploadComplete(uploadedFiles)

            return uploadedFiles
        } catch (error) {
            console.error("Error uploading files:", error)
            throw error
        } finally {
            updateIsLoading(false)
        }
    }, [selectedFiles, index, name])

    return (
        <CustomModal isOpen={isOpen} className={classNames("partner-professional-documents-modal", classes.Modal)}
                     contentClassName={classes.ModalContent}>
            <ModalHeader onClose={onClose} sectionClassName={classes.ModalHeader}
                         className={classNames(classes.TextBlack, "font-size-24 font-weight-semibold partner-text-black")}>
                Upload professional documents
            </ModalHeader>
            <ModalBody className={classes.ModalBody}>
                <DropZoneCard
                    className={classes.ModalDropzoneFile}
                    classNameUploadContainer={classes.ModalDropzoneFileContainer}
                    hideIcon
                    fileMask={DOCUMENT_FILE_MASK}
                    maxFiles={FILES_MAX_COUNT}
                    maxSize={MAX_PARTNER_FILE_SIZE}
                    isDropContainer={true}
                    onDrop={onDrop}
                    errorMessage={errorMessage}
                >
                    <div className="d-flex flex-column align-items-center">
                        <div className="mb-4">
                            <Icon icon="partnerDocument" width={54} height={54}/>
                        </div>
                        <div className="d-flex flex-column w-100 align-items-center">
                            <h3 className={classNames("font-size-20 font-weight-semibold mb-3", classes.TextBlack)}>Upload
                                file(s)</h3>
                            <p className={classNames("mb-2 font-size-16", classes.TextBlack)}>Drag and drop your pdf,
                                png or jpeg files here or <span
                                    className={classNames("cursor-pointer font-weight-semibold", classes.TextPrimary)}>click to upload</span>
                            </p>
                            <p className={classNames("mb-0 font-size-14", classes.TextSecondary)}>{`Maxi file size of ${MAX_DOCUMENT_FILE_SIZE/KILOBYTES_IN_MEGABYTE/BYTES_IN_KILOBYTE}
                                Mb`}</p>
                        </div>
                    </div>
                </DropZoneCard>

                <div className={classNames("font-size-16", classes.ModalNote)}>
                    <span className={classes.TextPrimary}>Note:</span> Please upload your relevant documents for
                    verification. Must be clear and legible (avoid blurry images).
                </div>

                {!!selectedFiles?.length &&
                <>
                    {selectedFiles.map((selectedFile, index) => {
                        return (
                            <div key={index} className="mt-3">
                                <div
                                    className={classNames(classes.FileItem, "d-flex align-items-center justify-content-between")}>
                                    <div className="d-flex align-items-center">
                                        <Icon icon="partnerDocument" width={32} height={32}/>
                                        <div className="d-flex flex-column justify-content-between px-3">
                                            <span className={classNames(classes.TextBlack, classes.FileName, "mb-1 font-size-16")}>
                                                {selectedFile.file.name}
                                            </span>
                                            <span className={classNames(classes.TextMuted, "font-size-14")}>
                                                {formatBytes(selectedFile.file.bytes, 2)}
                                            </span>
                                        </div>
                                    </div>
                                    {isUploading?.[index] === true
                                        ? <Spinner size="sm" className={classes.Spinner}/>
                                        : <Icon icon="partnerTrash" className="cursor-pointer ms-3" onClick={() => deleteDocument(index)}/>
                                    }
                                </div>
                            </div>
                        )
                    })}
                </>}
            </ModalBody>
            {!!selectedFiles?.length && <ModalActions>
                <div className={classNames(classes.ModalActions, "m-0")}>
                    <Button
                        color={BUTTON_COLORS.secondary}
                        type="button"
                        onClick={onClose}
                        className={classNames(classes.BtnSecondary, "me-3")}
                    >
                        <span className={classes.TextBlack}>Cancel</span>
                    </Button>
                    <Button
                        color={BUTTON_COLORS.primary}
                        type="button"
                        className={classes.BtnPrimary}
                        disabled={files?.length === FILES_MAX_COUNT || !isLoading}
                        onClick={() => {
                            return onConfirm()
                        }}
                    >
                        Confirm
                    </Button>
                </div>
            </ModalActions>}
        </CustomModal>
    )
}

export default UploadProfessionalDocuments;