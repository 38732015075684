import React from "react";
import {useNavigate} from "react-router-dom";
import TableSearch from "../../../../../base/components/Table/tableSearch";
import Button from "../../../../../base/components/Button";
import {BUTTON_COLORS} from "../../../../../base/components/Button/appearance";
import {useLocationQuery} from "../../../../../base/hooks/useQueryString";
import {useHighlight} from "../../../../../base/hooks/useHighlight";
import Icon from "../../../../../base/components/Icon";
import {ADMINS_GROUP_LINKS} from "../../config";
import {UncontrolledTooltip} from "reactstrap";
import joinClassNames from "../../../../../base/helpers/joinClassNames";

export const ROLES_ACTIONS = {
    DELETE: 'delete',
    EDIT: 'edit'
};

export const SearchPlaceholder = () => (
    <>
        No roles found
    </>
);

export const NoRolesPlaceholder = () => (
    <div className="text-center">
        No roles for now.<br/>
        Click the “Add new role” option to add a new one.
    </div>
);

export const TableHeader = ({searchProvider}) => {
    const navigate = useNavigate();

    const goToCreate = () => {
        navigate(ADMINS_GROUP_LINKS.FORM_ROLES);
    };

    return (
        <section className="d-flex w-100 pt-1 mb-3 align-items-center justify-content-between">
            <div className="d-flex align-items-center gap-4">
                <TableSearch
                    className="biomarkers-search"
                    search={searchProvider?.getValue()}
                    onSearch={searchProvider.setValue}
                    placeholder="Search role..."
                />
            </div>

            <Button color={BUTTON_COLORS.primary} onClick={goToCreate} className="d-flex align-items-center">
                <i className="bx bx-plus me-2"/>
                Add new role
            </Button>
        </section>
    );
};

export const columns = [
    {
        Header: "#",
        width: 35,
        className: "bg-white",
        Cell: (cellProps) => {
            const {params: {offset = 0}} = useLocationQuery();
            return <label className="mb-0">{cellProps.row.index + 1 + (offset ?? 0)}</label>;
        }
    },
    {
        Header: "Roles",
        accessor: "name",
        className: "bg-white",
        canSort: true,
        Cell: ({value}) => {
            const {params} = useLocationQuery();
            const {decorateText} = useHighlight(params.search);

            return (
                <section className="w-fit-content d-flex align-items-center">
                    <div {...decorateText(value)} className="text-truncate mb-0" style={{maxWidth: "33vw"}}/>
                </section>
            );
        }
    },
    {
        Header: "Permissions",
        accessor: "permissions",
        className: "bg-white",
        canSort: true,
        Cell: (cellProps) => {
            const permissionsCount = cellProps.row.original?.permissions?.length ?? 0
            const permissionsNames = cellProps.row.original?.permissions?.map((permission) => permission?.service?.name)
            return (
                <>
                    <div>
                        {`${permissionsCount} permission${permissionsCount > 1 ? 's' : ''}`}
                        {permissionsNames?.length && <Icon
                            id={`permissions-${cellProps.row.id}`}
                            icon={"infoCircleOutline"}
                            width={16}
                            height={16}
                            className={"cursor-pointer ms-2"}
                        />}
                    </div>
                    {permissionsNames?.length && <UncontrolledTooltip
                        popperClassName={joinClassNames(
                            "tooltip-alternative-name"
                        )}
                        placement="left"
                        target={`permissions-${cellProps.row.id}`}
                    >
                        <div className="d-flex flex-column align-items-start">
                            {permissionsNames?.map((name, index) => <div
                                className={joinClassNames(
                                    (index !== permissionsNames?.length - 1) && "mb-2",
                                    "font-size-13"
                                )}
                                key={index}
                            >
                                {name}
                            </div>)}
                        </div>
                    </UncontrolledTooltip>}
                </>

            );
        }
    },
    {
        Header: "Users",
        accessor: "users",
        className: "bg-white",
        canSort: true,
        Cell: (cellProps) => {
            const usersCount = cellProps.row.original?.users?.length ?? 0;
            const emails = cellProps.row.original?.users?.map((user) => user?.email)
            return <div className={joinClassNames(
                emails?.length && "cursor-pointer",
            )}>
                <div>
                    {usersCount}
                    {emails?.length && <Icon
                        id={`users-${cellProps.row.id}`}
                        icon={"infoCircleOutline"}
                        width={16}
                        height={16}
                        className={"cursor-pointer ms-2"}
                    />}
                </div>
                {emails?.length && <UncontrolledTooltip
                    popperClassName={joinClassNames(
                        "tooltip-alternative-name"
                    )}
                    placement="right"
                    target={`users-${cellProps.row.id}`}

                >
                    <ul className="d-flex flex-column align-items-start ps-2 mb-0">
                        {emails?.map((email, index) => <li
                            className={joinClassNames(
                                (index !== emails?.length - 1) && "mb-1",
                                "font-size-13"
                            )}
                            key={index}
                        >
                            {email}
                        </li>)}
                    </ul>
                </UncontrolledTooltip>}
            </div>
        }
    },
    {
        Header: " ",
        className: "bg-white",
        width: 100,
        Cell: ({
                   row: {
                       original: {id}
                   },
                   actions
               }) => {

            return <section
                className="d-flex gap-1 align-items-end justify-content-end"
                onClick={(event) => {
                    event.stopPropagation();
                }}
            >
                <Icon icon="edit"
                      width={20}
                      height={20}
                      className="me-4 cursor-pointer impacts-section__view"
                      onClick={(event) => {
                          event.stopPropagation();
                          actions[ROLES_ACTIONS.EDIT](id);
                      }}
                />

                <Icon
                    icon={"trashIcon"}
                    width={20}
                    height={20}
                    onClick={(event) => {
                        event.stopPropagation();
                        actions[ROLES_ACTIONS.DELETE](id);
                    }}
                    className="me-4 cursor-pointer"
                />
            </section>;
        }
    }
]