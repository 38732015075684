import yup from "../../../../validation/yup";
import {MAX_ROLE_NAME_LENGTH, MIN_ROLE_NAME_LENGTH} from "./constants";

export const initialValues = {
    name: "",
    editableServices: [],
    visibleServices: [],
    isOrganisationAdmin: false
};

export const validationSchema = yup.object().shape({
    name: yup.string()
        .trim()
        .min(MIN_ROLE_NAME_LENGTH)
        .max(MAX_ROLE_NAME_LENGTH)
        .required(),
    visibleServices: yup.array()
        .of(yup.number())
        .min(1, "A role must have at least 1 viewable service.")
})