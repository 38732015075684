import {BYTES_IN_KILOBYTE, KILOBYTES_IN_MEGABYTE} from "../../../../../../base/constants/shared";

export const REQUIRED_MESSAGE = 'This is required field';

export const NAME_MIN_LENGTH = 1;
export const NAME_MAX_LENGTH = 100;
export const REFERENCE_NUMBER_MAX_LENGTH = 20;
export const FILES_MAX_COUNT = 10;
export const MAX_CREDENTIALS_COUNT = 5;
export const MAX_PARTNER_FILE_SIZE = 20_971_520; // 20MB

export const PARTNER_DATE_OUTPUT_FORMAT = "dd / MMM / yyyy"

export const DOCUMENT_FILE_MASK = {
    "image/jpeg": [".jpg", ".jpeg"],
    "image/png": [".png"],
    "application/pdf": [".pdf"]
}

export const ALLOWED_DOCUMENT_FILE_EXTENSION = ["jpg", "jpeg", "png", "pdf"];
export const ERROR_ALLOWED_DOCUMENT_FILE_TYPE_MESSAGE = "File is not supported. Please upload a jpg, jpeg, png and pdf format file";
export const ERROR_COUNT_DOCUMENT_FILE_TYPE_MESSAGE = `Maximum ${FILES_MAX_COUNT} files per Professional credential`;
export const MAX_DOCUMENT_FILE_SIZE = 20 * BYTES_IN_KILOBYTE * KILOBYTES_IN_MEGABYTE; // 20MB
export const ERROR_DOCUMENT_FILE_SIZE_MESSAGE = `Invalid file size. Max. size ${MAX_DOCUMENT_FILE_SIZE/KILOBYTES_IN_MEGABYTE/BYTES_IN_KILOBYTE} mb`;

