import * as _ from "lodash";

import { DateTime } from "luxon";
import { initService } from "../base/hooks/useService";
import Http from "./Http";
import StorageService from "./StorageService";

const CACHE_TIME = 60; // 60 seconds

export const ThemeTypes = {
  white: 1,
  dark: 2
};

export const SystemTypes = {
  backend: 1,
  frontend: 2,
  admin: 3,
}

export const WHITELISTED_BACKEND_VARIABLES = ["FRONTEND_BASE_URL"];

class ConfigService extends Http  {

  static $displayName = "ConfigService";

  async initConfig() {
    const storageService = initService(StorageService);

    let cachedConfig = storageService.get("system:variables");

    if (!cachedConfig) {
      const [adminConfig, backendConfig] = await Promise.all([
        this.fetchConfig(SystemTypes.admin),
        this.fetchConfig(SystemTypes.backend)
      ]);

      const data  = [
        ...adminConfig.data,
        ...backendConfig.data.filter(variable => WHITELISTED_BACKEND_VARIABLES.includes(variable.variable)),
      ];

      cachedConfig = _.chain(data).keyBy("variable").mapValues("value").value();
      storageService.set("system:variables", cachedConfig);
    }

    return cachedConfig;
  }

  async initThemeSettings() {
    const storageService = initService(StorageService);

    let cachedThemeSettings = storageService.get("system:themeSettings");

    if (!cachedThemeSettings) {
      const { data } = await this.fetchThemeSettings();

      cachedThemeSettings = _.chain(data)
        .groupBy("themeType")
        .mapValues(theme =>
          _.chain(theme).keyBy("propertyName").mapValues("value").value()
        )
        .value();
      storageService.set("system:themeSettings", cachedThemeSettings);
    }

    return cachedThemeSettings;
  }

  async initServicesSettings(organisationId) {
    const storageService = initService(StorageService);

    let cachedServicesSettings = storageService.get("system:servicesSettings");

    const settingsCachedAt = storageService.get("system:settings:cachedAt");
    const isCacheExpired =
      DateTime.now().toUnixInteger() - settingsCachedAt > CACHE_TIME;

    if (!cachedServicesSettings || isCacheExpired) {
      const { data } = await this.fetchServiceSettings(organisationId, { isEnabled: true });

      cachedServicesSettings = _.chain(data)
        .map(setting => _.pick(setting, ["name", "isEnabled"]))
        .keyBy("name")
        .mapValues("isEnabled")
        .value();

      storageService.set("system:servicesSettings", cachedServicesSettings);
      storageService.set(
        "system:settings:cachedAt",
        DateTime.now().toUnixInteger()
      );
    }

    return cachedServicesSettings;
  }

  async fetchConfig(type = SystemTypes.admin) {
    return await this.get(
      `/system/admin/variables`, {
        params: {
          type: type
        }
      }
    );
  }

  async fetchThemeSettings() {
    return await this.get(
      `/system/admin/settings`
    );
  }

  async fetchCustomerConfig(SYSTEM_TYPE = SystemTypes.frontend) {
    return await this.get(
      `/system/admin/variables`, {
        params: {
          type: SYSTEM_TYPE
        }
      }
    );
  }

  async fetchFrontendConfig() {
    return await this.get(
        `/system/frontend/variables`
    );
  }

  async updateCustomerConfig(config) {
    return await this.put('/system/admin/settings/variables', { data: config })
  }

  async updateThemeSettings(settings) {
    return await this.put('/system/admin/settings/properties', { data: settings })
  }

  async fetchServiceSettings(organisationId, params) {
    return this.get(`/organisations/${organisationId}/services`, { params });
  }

  async fetchMobileServices() {
    return this.get("/system/admins/mobile-services");
  }

  async updateMobileServices(type, services) {
    return this.patch("/system/admins/mobile-services", {type, data: services });
  }
}

export default ConfigService;
