export const MIN_STRING_LENGTH = 1
export const MAX_TITLE_LENGTH = 100

export const MIN_ITEMS_QUANTITY = 1

export const ALLOWED_SYMBOLS_REGEX = /^[a-zA-Z0-9' _-]*$/;

export const MOBILE_SERVICE_TYPES = {
    features: 1,
    widgets: 2
}

export const BOTTOM_NAV_ELEMENTS = {
    exploreContent: 9,
    nutritionProgram: 10,
    fitnessProgram: 11,
    wellnessProgram: 12,
    lifeStyleDashboard: 13,
    mirrorMirror: 14,
    mindAndBody: 15,
    labResults: 16,
    menu: 0
}

export const MOBILE_WIDGET_IDS = {
    dailyProgress: 25,
    waterIntake: 26,
    deviceData: 27,
    mirrorMirrorMind: 28,
    mirrorMirrorBody: 29,
    mood: 30,
    fasting: 31,
}
