import yup from "../../../../validation/yup";
import {
  MAX_RULE_NAME_LENGTH,
  MAX_SKU_LENGTH,
  MIN_STRING_LENGTH
} from "../../Biomarkers/CreateEdit/constants";
import {
    FREQUENCY_PER_DAY_MAX_VALUE, FREQUENCY_PER_DAY_MIN_VALUE,
    HIERARCHY_OPTIONS,
    IDEAL_TIME_OF_DAY_IDS,
    IDEAL_TIME_OF_DAY_MAP,
    MAX_BENEFIT_ITEMS,
    MAX_TIME_OF_DAY_ITEMS,
    SKIN_RECOMMENDATIONS_IDS
} from "./constants";

const IdealTypesRequirements = (value, context) => {
  if(SKIN_RECOMMENDATIONS_IDS.includes(context.parent.category?.id)){
    return !!value?.id
  }
  return true
}

export const validationSchema = yup.object().shape({
  title: yup.string()
    .trim()
    .min(MIN_STRING_LENGTH)
    .max(MAX_RULE_NAME_LENGTH)
    .required(),
  category: yup.object().required(),
  file: yup.array().min(1).required(),
  idealFrequency: yup.object().test("is required", "Ideal frequency is required", IdealTypesRequirements),
  SKU: yup.string()
    .min(MIN_STRING_LENGTH)
    .max(MAX_SKU_LENGTH)
    .matches(/^[A-Za-z0-9_-]+$/ , 'SKU is not correct')
    .nullable()
    .optional(),
  benefitsIds: yup.array()
      .of(yup.number())
      .max(MAX_BENEFIT_ITEMS, `You can select up to ${MAX_BENEFIT_ITEMS} benefits`),
  idealTimeOfDay: yup.array()
      .of(yup.number())
      .max(MAX_BENEFIT_ITEMS, `You can select up to ${MAX_TIME_OF_DAY_ITEMS} elements`)
      .test('valid-combination', 'Invalid combination of time of day types', function(value) {
        if (!value) return true; // Allow empty arrays

        // If 'anytime' is selected, no other option should be present
        if (value.includes(IDEAL_TIME_OF_DAY_IDS.anytime) && value.length > 1) {
          return this.createError({ message: `When "${IDEAL_TIME_OF_DAY_MAP[IDEAL_TIME_OF_DAY_IDS.anytime].label}" is selected, no other option can be chosen` });
        }

        // If 'morningAndNight' is selected, 'morning' and 'night' cannot be selected
        if (value.includes(IDEAL_TIME_OF_DAY_IDS.morningAndNight) &&
          (value.includes(IDEAL_TIME_OF_DAY_IDS.morning) || value.includes(IDEAL_TIME_OF_DAY_IDS.night))) {
            return this.createError({
              message: `When "${IDEAL_TIME_OF_DAY_MAP[IDEAL_TIME_OF_DAY_IDS.morningAndNight].label}" is selected, "${IDEAL_TIME_OF_DAY_MAP[IDEAL_TIME_OF_DAY_IDS.morning].label}" and "${IDEAL_TIME_OF_DAY_MAP[IDEAL_TIME_OF_DAY_IDS.night].label}" cannot be chosen separately`
            });
        }

        return true;
      })
      .when('hierarchyType', {
          is: HIERARCHY_OPTIONS.CHILD,
          then: schema => schema.min(1, "Best time of day is required")
      }),
    supplementName: yup.string()
        .when('hierarchyType', {
            is: HIERARCHY_OPTIONS.CHILD,
            then: yup.string().min(1).required()
        }),
    frequencyPerDay: yup.number()
        .when('hierarchyType', {
            is: HIERARCHY_OPTIONS.CHILD,
            then: yup.number()
                .integer('Frequency per day must be an integer')
                .min(FREQUENCY_PER_DAY_MIN_VALUE, `Frequency per day must be greater than or equal to ${FREQUENCY_PER_DAY_MIN_VALUE}`)
                .max(FREQUENCY_PER_DAY_MAX_VALUE, `Frequency per day must be less than or equal to ${FREQUENCY_PER_DAY_MAX_VALUE}`)
        }),
});


export const impactValidationSchema = yup.object().shape({
  biomarker: yup.object().required(),
});