import React, { useCallback, useEffect, useState } from 'react';
import {useService} from "../../../../../base/hooks/useService";
import AdminsService from "../../../../../services/AdminsService";
import {useLoading} from "../../../../../base/hooks/useLoading";
import {useLocation, useNavigate} from "react-router-dom";
import {DEFAULT_TABLE_LIMIT} from "../../../../../base/constants/shared";
import {useQueryString} from "../../../../../base/hooks/useQueryString";
import {
    useLocationSource,
    usePaginationProvider,
    useSearchProvider,
    useSortProvider
} from "../../../../../base/components/Table/hooks";
import StorageService from "../../../../../services/StorageService";
import {KEY_USER} from "../../../../../base/constants/storage";
import {NoRolesPlaceholder, ROLES_ACTIONS, SearchPlaceholder, TableHeader, columns} from "./components";
import Table from "../../../../../base/components/Table";
import {ADMINS_GROUP_LINKS} from "../../config";
import ToasterService from "../../../../../services/ToastService";
import ConfirmPopup from "../../../../../base/components/ConfirmPopup";

export default function Roles() {
    /**
     * @type {AdminsService}
     */
    const adminsService = useService(AdminsService);
    /**
     * @type {StorageService}
     */
    const storage = useService(StorageService);
    /**
     * @type {ToasterService}
     */
    const toastService = useService(ToasterService);

    const DEFAULT_OPTIMALLY_ME_ORG = 1;
    const { organisationId } = storage.get(KEY_USER, { organisationId: DEFAULT_OPTIMALLY_ME_ORG });

    const [isLoading, { registerPromise }] = useLoading(true);
    const { search: locationSearch } = useLocation();
    const {
        params: {
            limit = DEFAULT_TABLE_LIMIT,
            offset = 0,
            search,
            name,
            permissions,
            users,
        }
    } = useQueryString(locationSearch);

    const [roles, updateRoles] = useState([]);
    const [rolesPagination, updateRolesPagination] = useState({});
    const [showDeletePopup, updateShowDeletePopup] = useState(null);

    const navigate = useNavigate();
    const locationSource = useLocationSource();

    const paginationProvider = usePaginationProvider({
        source: locationSource,
        alias: "offset",
        scope: "",
        fallback: 0
    });

    const searchProvider = useSearchProvider({
        source: locationSource,
        scope: "",
        alias: 'search',
        onApplyClearScope: [""]
    });

    const sortKeys = ["name", "permissions", "users"];
    const getSortScope = (key) => [...sortKeys.filter(item => item !== key), "offset"];

    const nameSortProvider = useSortProvider({
        source: locationSource,
        alias: "name",
        scope: "",
        onApplyClearScope: getSortScope("name")
    });

    const permissionsSortProvider = useSortProvider({
        source: locationSource,
        alias: "permissions",
        scope: "",
        onApplyClearScope: getSortScope("permissions")
    });

    const usersSortProvider = useSortProvider({
        source: locationSource,
        alias: "users",
        scope: "",
        onApplyClearScope: getSortScope("users")
    });

    const goToEdit = (id) => {
        const queryParams = new URLSearchParams({ editRoleId: id }).toString();
        navigate(`${ADMINS_GROUP_LINKS.FORM_ROLES}/?${queryParams}`);
    }

    const deleteRole = (id) => {
        adminsService.deleteRole(organisationId, id)
            .then(() => {
                getRoles()
                toastService.success("The role has been successfully deleted")
            })
    }

    const getRoles = useCallback(() => {
        const [orderBy, orderType] = Object.entries({
            name,
            permissions,
            users,
        }).find(([_, value]) => value) || [];

        registerPromise(adminsService.getAdminsRoles(organisationId, {
            limit,
            offset,
            orderBy,
            orderType,
            query: search,
        }).then(({ data, pagination }) => {
            updateRoles(data);
            updateRolesPagination(pagination);
        }));
    }, [organisationId, limit, offset, name, permissions, users, search]);

    useEffect(() => {
        getRoles();
    }, [getRoles]);

    return (
        <div className="mt-4">
            <Table
                columns={roles.length ? columns : []}
                data={roles}
                loading={isLoading}
                HeaderComponent={TableHeader}
                totalCount={rolesPagination.totalCount}
                limit={DEFAULT_TABLE_LIMIT}
                offset={offset}
                paginationProvider={paginationProvider}
                searchProvider={searchProvider}
                commonPlaceholder={<NoRolesPlaceholder/>}
                placeholderForSearch={<SearchPlaceholder/>}
                isRowClick={false}
                actions={{
                    [ROLES_ACTIONS.EDIT]: goToEdit,
                    [ROLES_ACTIONS.DELETE]: updateShowDeletePopup,
                }}
                sortProviders={{
                    name: nameSortProvider,
                    permissions: permissionsSortProvider,
                    users: usersSortProvider,
                }}
                tableClassName="overflow-x-visible"
            />
            {showDeletePopup &&
            <ConfirmPopup
                isOpen={showDeletePopup}
                updateIsOpen={updateShowDeletePopup}
                onSubmit={() => {
                    deleteRole(showDeletePopup)
                    updateShowDeletePopup(null);
                }}
                title="Delete role"
                description="Are you sure you want to delete the chosen role?"
                submitBtnText="Delete"
                className="upload-manually__popup"
            />
            }
        </div>
    )
}