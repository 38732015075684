import React from "react";

import partnerLogo from "../../../assets/images/partners/partners-logo.svg";
import {useService} from "../../hooks/useService";
import StorageService from "../../../services/StorageService";
import {KEY_USER} from "../../constants/storage";
import {Link, useLocation} from "react-router-dom";
import {USER_ROLES} from "../../constants/shared";
import {AUTH_GROUP_LINKS} from "../../../groups/auth/config";

const EXCLUDE_LOGOUT = {
    [AUTH_GROUP_LINKS.LINK_PARTNER_SIGN_UP]: true,
    [AUTH_GROUP_LINKS.LINK_PARTNER_CREATING_ACCOUNT]: true,
};

const HeaderPartner = () => {
    /**
     * @type {StorageService}
     */
    const storage = useService(StorageService);
    const location = useLocation();

    const { firstName = "", lastName = "", role } = storage.get(KEY_USER, { firstName: "", lastName: "", role: USER_ROLES.user });
    const hasLogout = (role === USER_ROLES.practitioner && firstName && lastName) && !EXCLUDE_LOGOUT[location.pathname]

    return(
        <div className="d-flex justify-content-between align-items-center">
            <div className="auth-partner-layout-logo">
                <img src={partnerLogo} alt="logo" />
            </div>
            <div>
                {hasLogout && <Link to="/auth/logout" className="d-flex align-content-center auth-partner-logout">
                    Log out
                </Link>}
            </div>
        </div>
    )
}

export default HeaderPartner;