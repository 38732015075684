export const ADMINS_GROUP_ROUTES = {
    BASE: "/",
    LISTS: "/lists/*",
    FORM_ROLES: "/form/roles",
};

export const ADMINS_GROUP_LINKS = {
    BASE: "/app/admins",
    LIST: "/app/admins/lists",
    ADMINS_LIST: "/app/admins/lists/admins",
    ROLES_LIST: "/app/admins/lists/roles",
    FORM_ROLES: "/app/admins/form/roles",
};