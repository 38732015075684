export const HTTPS_PATTERN = /^((http|https):\/\/s3)/;
export const PASSWORD_PATTERN = /^(?=.*[A-Z])(?=.*[a-z])(?=.*[!@#$%^&*()?|]?)(?=.*\d)[\S]+$/;
export const ONLY_NUMBERS_PATTERN = /[^0-9]/g

export const URL_PATTERN = /^(https?:\/\/)?(www\.)?([a-zA-Z0-9-]+(\.[a-zA-Z]{2,})+)(\/[^\s]*)?(\?[^\s]*)?(#[^\s]*)?$/

export const VIMEO_VIDEO_PATTERN = /^https:\/\/(www\.)?vimeo\.com\/.+$/
export const AUTHOR_NAME_PATTERN = /^[A-Za-z\s\-0-9]+$/
export const NAME_PATTERN = /^(?=.*[A-Z]?)(?=.*[a-z]?)(?=.*[-]?)[\S]+$/

