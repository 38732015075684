import React from "react";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { useField } from "formik";
import {startsWith} from "lodash";

const UK_CODE_COUNTRY = "gb"

const FormikPhoneInputField = ({ name, label, placeholder = '+ 44 000 000 00 00', country = UK_CODE_COUNTRY, afterOnChange = () => {}, ...props }) => {
    const [field, meta, helpers] = useField(name);
    const { setValue, setTouched } = helpers;

    return (
        <div>
            <label>{label}</label>
            <PhoneInput
                country={country}
                onlyCountries={[country]}
                preferredCountries={[country]}
                disableInitialCountryGuess={false}
                isValid={(inputNumber, country, countries) => {
                    return countries.some((country) => {
                        return startsWith(inputNumber, country.dialCode) || startsWith(country.dialCode, inputNumber);
                    });
                }}
                defaultCountry={country}
                containerClass={`${meta.touched && meta.error ? 'is-invalid without-error-icon' : ''}`}
                inputProps={{ name: name, }}
                // countryCodeEditable={false}
                placeholder={placeholder}
                value={field.value}
                onChange={(value) => {
                    setTouched(true);
                    setValue(value)
                    afterOnChange();
                }}
                onBlur={() => setTouched(true)}
                inputClass={`form-control w-100 ${meta.touched && meta.error ? 'is-invalid without-error-icon' : ''}`}
                specialLabel=""
                masks={{gb: '... ... .. ..'}}
                {...props}
            />
            {meta.touched && meta.error && <span className="invalid-feedback">{meta.error}</span>}
        </div>
    );
};

export default FormikPhoneInputField;
