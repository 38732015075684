import React from "react";
import {Formik} from "formik";
import {ModalFooter} from "reactstrap";

import {useModal} from "../../../../../base/hooks/useModal";
import joinClassNames from "../../../../../base/helpers/joinClassNames";
import {initialValues, validationSchema} from "./form";
import Button from "../../../../../base/components/Button";
import {BUTTON_COLORS} from "../../../../../base/components/Button/appearance";
import FormikInput from "../../../../../base/components/FormikInput";
import {useService} from "../../../../../base/hooks/useService";
import PartnersService from "../../../../../services/PartnersService";
import ToasterService from "../../../../../services/ToastService";
import {useLoading} from "../../../../../base/hooks/useLoading";

const InvitePractitionerModalForm = ({isOpen, onClose}) => {
    /**
     * @type {PartnersService}
     */
    const partnersService = useService(PartnersService);
    /**
     * @type {ToasterService}
     */
    const toastService = useService(ToasterService);

    const [isLoading, {registerPromise}] = useLoading(false);
    const {Modal} = useModal();

    const invitePractitioner = (values, formikHelpers) => {
        formikHelpers.setSubmitting(true);
        registerPromise(partnersService.sendInvitationEmail(values))
            .then(() => {
                toastService.success(`Invitation email to ${values.email} successfully sent`)
                onClose()
            })
            .catch((error) => {
                toastService.error(error.message);
            })
            .finally(() => {
                formikHelpers.setSubmitting(false);
            })
    }

    return (
        <Modal isOpen={isOpen} toggle={() => onClose()} size="md"
               className="practitioner-modal fontStyles" >
            <Modal.Header onClose={() => onClose()} className="font-weight-semibold text-dark">
                Invite practitioner
            </Modal.Header>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                validateOnBlur
                onSubmit={(values, formikHelpers) => invitePractitioner(values, formikHelpers)}
                enableReinitialize
            >
                {({errors, handleSubmit, isSubmitting}) => {

                    return (
                        <form
                            className={joinClassNames("form-horizontal p-2", isSubmitting && "pointer-events-none")}
                            onSubmit={handleSubmit}
                        >
                            <Modal.Body>
                                <div className="d-flex flex-column mb-3">
                                    <FormikInput
                                        type="email"
                                        name="email"
                                        label="Practitioner’s email"
                                        placeholder="practitioner@email.com"
                                    />
                                </div>
                            </Modal.Body>
                            <ModalFooter>
                                <div className="d-flex justify-content-end gap-2">
                                    <Button
                                        color={BUTTON_COLORS.primaryOutline}
                                        type="button"
                                        onClick={onClose}
                                    >
                                        Cancel
                                    </Button>

                                    <Button
                                        color={BUTTON_COLORS.primary}
                                        type="submit"
                                        disabled={!!Object.keys(errors).length || isSubmitting}
                                        loading={isLoading}
                                    >
                                        Send invite
                                    </Button>
                                </div>
                            </ModalFooter>
                        </form>
                    )
                }}
            </Formik>
        </Modal>
    )
}

export default InvitePractitionerModalForm;