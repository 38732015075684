export const PRACTITIONER_STATUSES = {
    approved: 2,
    waitlisted: 4,
    pending: 1,
    rejected: 3,
}

export const PRACTITIONER_STATUSES_LABELS = {
    [PRACTITIONER_STATUSES.approved]: "Approved",
    [PRACTITIONER_STATUSES.waitlisted]: "Waitlisted",
    [PRACTITIONER_STATUSES.pending]: "Pending",
    [PRACTITIONER_STATUSES.rejected]: "Rejected",
}

export const practitionerStatusesOption = Object.values(PRACTITIONER_STATUSES).map((value) => ({
    id: value,
    value,
    label: PRACTITIONER_STATUSES_LABELS[value]
}));