import yup from "../../../../../../validation/yup";
import {generateMinInvalidMessage} from "../../../../../../validation/user";
import {REQUIRED_MESSAGE} from "../Step1/constants";
import {MAX_CLINIC_NAME_LENGTH, MIN_CLINIC_NAME_LENGTH} from "./constants";

const websiteRegExp = /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/


    export const validationSchema = yup.object().shape({
    clinicName: yup.string()
        .min(MIN_CLINIC_NAME_LENGTH, generateMinInvalidMessage('Clinic name', MIN_CLINIC_NAME_LENGTH))
        .max(MAX_CLINIC_NAME_LENGTH)
        .required(REQUIRED_MESSAGE),
    country: yup.array().nullable().min(1, REQUIRED_MESSAGE).required(REQUIRED_MESSAGE),
    city: yup.array().nullable().min(1, REQUIRED_MESSAGE).required(REQUIRED_MESSAGE),
    address: yup.string().required(REQUIRED_MESSAGE),
    website: yup.string()
        .matches(websiteRegExp, 'Please enter a valid website URL, including the domain (e.g., example.com or example.io)')
})