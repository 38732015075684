import React from 'react';
import { Container } from 'reactstrap';
import {Navigate, Route, Routes} from 'react-router-dom';
import {ADMINS_GROUP_LINKS, ADMINS_GROUP_ROUTES} from "./config";
import Lists from "./Lists";
import {RoleForm} from "./RoleForm";

export default function Admins() {

    return (
        <Container fluid className="content">
            <Routes>
                <Route
                    path={ADMINS_GROUP_ROUTES.LISTS}
                    element={<Lists />}
                />

                <Route
                    path={ADMINS_GROUP_ROUTES.FORM_ROLES}
                    element={<RoleForm />}
                />

                <Route path={ADMINS_GROUP_ROUTES.BASE} element={<Navigate to={ADMINS_GROUP_LINKS.LIST} />} />
            </Routes>
        </Container>
    )
}