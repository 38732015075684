import React, {useCallback, useEffect, useState} from 'react';
import {useService} from "../../../../../base/hooks/useService";
import AdminsService from "../../../../../services/AdminsService";
import StorageService from "../../../../../services/StorageService";
import ToasterService from "../../../../../services/ToastService";
import {KEY_USER} from "../../../../../base/constants/storage";
import {useLoading} from "../../../../../base/hooks/useLoading";
import {useLocation} from "react-router-dom";
import {DEFAULT_TABLE_LIMIT} from "../../../../../base/constants/shared";
import {useQueryString} from "../../../../../base/hooks/useQueryString";
import {
    useLocationSource,
    usePaginationProvider,
    useSearchProvider,
    useSortProvider
} from "../../../../../base/components/Table/hooks";
import Table from "../../../../../base/components/Table";
import {ADMINS_ACTIONS, columns, NoAdminsPlaceholder, SearchPlaceholder, TableHeader} from "./components";
import ConfirmPopup from "../../../../../base/components/ConfirmPopup";

export default function Admins() {
    /**
     * @type {AdminsService}
     */
    const adminsService = useService(AdminsService);
    /**
     * @type {StorageService}
     */
    const storage = useService(StorageService);
    /**
     * @type {ToasterService}
     */
    const toastService = useService(ToasterService);

    const DEFAULT_OPTIMALLY_ME_ORG = 1;
    const {organisationId} = storage.get(KEY_USER, {organisationId: DEFAULT_OPTIMALLY_ME_ORG});

    const [isLoading, {registerPromise}] = useLoading(true);
    const {search: locationSearch} = useLocation();
    const {
        params: {
            limit = DEFAULT_TABLE_LIMIT,
            offset = 0,
            search,
            name,
            email,
            role,
            status
        }
    } = useQueryString(locationSearch);

    const [admins, updateAdmins] = useState([]);
    const [adminsPagination, updateAdminsPagination] = useState({});
    const [showDeletePopup, updateShowDeletePopup] = useState(null);

    const locationSource = useLocationSource();

    const paginationProvider = usePaginationProvider({
        source: locationSource,
        alias: "offset",
        scope: "",
        fallback: 0
    });

    const searchProvider = useSearchProvider({
        source: locationSource,
        scope: "",
        alias: 'search',
        onApplyClearScope: [""]
    });

    const sortKeys = ["name", "email", "role", "status"];
    const getSortScope = (key) => [...sortKeys.filter(item => item !== key), "offset"];

    const nameSortProvider = useSortProvider({
        source: locationSource,
        alias: "name",
        scope: "",
        onApplyClearScope: getSortScope("name")
    });

    const emailSortProvider = useSortProvider({
        source: locationSource,
        alias: "email",
        scope: "",
        onApplyClearScope: getSortScope("email")
    });

    const roleSortProvider = useSortProvider({
        source: locationSource,
        alias: "role",
        scope: "",
        onApplyClearScope: getSortScope("role")
    });

    const statusSortProvider = useSortProvider({
        source: locationSource,
        alias: "status",
        scope: "",
        onApplyClearScope: getSortScope("status")
    });

    const deleteAdmin = (id) => {
        adminsService.deleteAdmin(organisationId, id)
            .then(() => {
                getAdminsList()
                toastService.success("The admin has been successfully deleted")
            })
    }

    const resendInviteToAdmin = (model) => {
        adminsService.resendInvitation(organisationId, model)
            .then(() => {
                toastService.success("The invitation has been successfully sent")
            })
    }

    const changeAdminStatus = (userId, model) => {
        adminsService.changeAdminStatusById(organisationId, userId, model)
            .then(() => {
                getAdminsList()
                toastService.success("The admins status has been successfully changed")
            })
    }

    const getAdminsList = useCallback(() => {
        const [orderBy, orderType] = Object.entries({
            name,
            email,
            role,
            status
        }).find(([_, value]) => value) || [];

        registerPromise(adminsService.getAdmins(organisationId, {
            limit,
            offset,
            orderBy,
            orderType,
            query: search,
        }).then(({data, pagination}) => {
            updateAdmins(data);
            updateAdminsPagination(pagination);
        }));
    }, [organisationId, limit, offset, name, email, role, status, search]);

    useEffect(() => {
        getAdminsList()
    }, [getAdminsList])

    return (
        <div className="mt-4">
            <Table
                columns={admins.length ? columns : []}
                data={admins}
                loading={isLoading}
                HeaderComponent={TableHeader}
                totalCount={adminsPagination.totalCount}
                limit={DEFAULT_TABLE_LIMIT}
                offset={offset}
                paginationProvider={paginationProvider}
                searchProvider={searchProvider}
                commonPlaceholder={<NoAdminsPlaceholder/>}
                placeholderForSearch={<SearchPlaceholder/>}
                isRowClick={false}
                actions={{
                    [ADMINS_ACTIONS.DELETE]: updateShowDeletePopup,
                    [ADMINS_ACTIONS.RESEND_INVITE]: resendInviteToAdmin,
                    [ADMINS_ACTIONS.CHANGE_STATUS]: changeAdminStatus
                }}
                sortProviders={{
                    name: nameSortProvider,
                    email: emailSortProvider,
                    role: roleSortProvider,
                    status: statusSortProvider
                }}
                tableClassName="overflow-x-visible"
            />
            {showDeletePopup &&
            <ConfirmPopup
                isOpen={showDeletePopup}
                updateIsOpen={updateShowDeletePopup}
                onSubmit={() => {
                    deleteAdmin(showDeletePopup)
                    updateShowDeletePopup(null);
                }}
                title="Delete admin"
                description="Are you sure you want to delete the chosen admin?"
                submitBtnText="Delete"
                className="upload-manually__popup"
            />
            }
        </div>
    )
}