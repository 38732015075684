import React, {useEffect, useMemo, useState} from "react";
import {useLocationQuery} from "../../../../base/hooks/useQueryString";
import {MIN_SEARCH_LENGTH} from "../../../../base/constants/pagination";
import {useHighlight} from "../../../../base/hooks/useHighlight";
import Button from "../../../../base/components/Button";
import {BUTTON_COLORS} from "../../../../base/components/Button/appearance";
import {useService} from "../../../../base/hooks/useService";
import PartnersService from "../../../../services/PartnersService";
import {getArray} from "../../HealthProgram/helpers";
import {Dropdown, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledTooltip} from "reactstrap";
import Checkbox from "../../../../base/components/Checkbox";
import {PRACTITIONER_STATUSES, PRACTITIONER_STATUSES_LABELS, practitionerStatusesOption} from "../constants";
import joinClassNames from "../../../../base/helpers/joinClassNames";
import {formatISODate} from "../../../../base/helpers/date";

import SpecialisationBadge from './SpecialisationBadge.module.scss';
import classnames from "classnames";
import Icon from "../../../../base/components/Icon";

export const statusesPillClasses = {
    [PRACTITIONER_STATUSES.approved]: 'active-pill',
    [PRACTITIONER_STATUSES.waitlisted]: 'waitlisted-pill',
    [PRACTITIONER_STATUSES.pending]: 'deactivated-pill',
    [PRACTITIONER_STATUSES.rejected]: 'inactivated-pill'
}

export const THREE_DOT_TYPES = {
    view: "VIEW",
    approve: "APPROVE",
    waitlist: "WAITLIST",
    reject: "REJECT",
    delete: "DELETE"
};

export const VIEW_DETAILS = { id: 1, label: "View details", icon: "practitionersEye", type: THREE_DOT_TYPES.view };
export const APPROVE_PRACTITIONER = { id: 2, label: "Approve", icon: "practitionersApprove", type: THREE_DOT_TYPES.approve };
export const WAITLIST_PRACTITIONER = { id: 3, label: "Waitlist", icon: "practitionersWaitlist", type: THREE_DOT_TYPES.waitlist };
export const REJECT_PRACTITIONER = { id: 4, label: "Reject", icon: "practitionersReject", labelClass: "text-danger", type: THREE_DOT_TYPES.reject };
export const DELETE_PRACTITIONER = { id: 4, label: "Delete", icon: "practitionersDelete", labelClass: "text-danger", type: THREE_DOT_TYPES.delete };

const PENDING_ACTIONS = [
    VIEW_DETAILS,
    APPROVE_PRACTITIONER,
    WAITLIST_PRACTITIONER,
    REJECT_PRACTITIONER,
    DELETE_PRACTITIONER
]

const APPROVED_REJECTED_ACTIONS = [
    VIEW_DETAILS,
    DELETE_PRACTITIONER
]

const WAITLISTED_ACTIONS = [
    VIEW_DETAILS,
    APPROVE_PRACTITIONER,
    REJECT_PRACTITIONER,
    DELETE_PRACTITIONER
]


export const SearchPlaceholder = () => (
    <>
        No practitioners found.
    </>
);

export const NoPractitionersPlaceholder = () => (
    <div className="text-center">
        No practitioners for now..<br/>
        Click the “Invite Practitioners” option to add a new one.
    </div>
);

export const TableFilter = ({ filterProvider, specialisationOptions = [] }) => {
    const {statuses, specialisationsIds} = filterProvider;

    const [isOpen, updateIsOpen] = useState(false);
    const [specialisations, updateSpecialisationsIds] = useState(getArray(specialisationsIds.getValue()));
    const [statusFilter, updateStatusFilter] = useState(getArray(statuses.getValue()));

    const submitFilters = () => {
        specialisationsIds.setValue(specialisations);
        statuses.setValue(statusFilter);
        updateIsOpen(false);
    }

    const handleCheckboxChange = (id, setState) => {
        setState(prevState => {
            return prevState.includes(id) ? prevState.filter(item => item !== id) : [...prevState, id];
        });
    }

    return(
        <section className="d-flex align-items-center">
            <Dropdown
                isOpen={isOpen}
                toggle={() => updateIsOpen(prevState => !prevState)}
                className="d-inline-block"
            >
                <DropdownToggle
                    className=" btn btn-outline-primary d-flex align-items-center"
                    tag="button"
                >
                    <i className="bx bx-filter-alt me-2"/> Add filter
                </DropdownToggle>

                <DropdownMenu className="filter-menu p-2 pt-3 rec-filter">

                    <section className="d-flex justify-content-between">
                        <section className="filter-options mx-1 me-4 custom-scrollbar rec-category-filter">
                            <label className="text-secondary font-size-10 mb-1 font-weight-normal">BY SPECIALISATION</label>
                            {specialisationOptions.map((specialisation, index) => {
                                return (
                                    <Checkbox
                                        id={specialisation.id}
                                        text={specialisation.label}
                                        value={specialisations?.includes(specialisation.id)}
                                        onChange={() => {
                                            handleCheckboxChange(specialisation.id, updateSpecialisationsIds)
                                        }}
                                        key={index}
                                        className="my-2 ms-1"
                                    />
                                )
                            })}
                        </section>

                        <section className="filter-options mx-1 custom-scrollbar rec-activity-filter">
                            <label className="text-secondary font-size-10 mb-1 font-weight-normal">BY STATUS</label>
                            {practitionerStatusesOption.map((status, index) => {
                                return (
                                    <Checkbox
                                        id={status.label}
                                        text={status.label}
                                        value={statusFilter?.includes(status.id)}
                                        onChange={() => handleCheckboxChange(status.id, updateStatusFilter)}
                                        key={index}
                                        className="my-2 ms-1"
                                    />
                                )
                            })}
                        </section>
                    </section>

                    <section className="w-100 px-2 pb-2 mt-3">
                        <Button color={BUTTON_COLORS.primary} onClick={submitFilters} className="w-100">
                            Apply
                        </Button>
                    </section>

                </DropdownMenu>
            </Dropdown>

            {(!!statuses.getValue() || !!specialisationsIds.getValue()) &&
            <Button
                color={BUTTON_COLORS.transparent}
                onClick={() => {
                    updateSpecialisationsIds([]);
                    updateStatusFilter([])
                    statuses.setValue();
                    specialisationsIds.setValue();
                }}
                className="text-danger no-border ms-2"
            >
                Clear filter
            </Button>
            }
        </section>
    )
}

export const TableHeader = ({ searchProvider, filterProvider, headerActions }) => {
    const [specialisations, setSpecialisations] = useState([])

    const goToInvite = () => {
        headerActions.onClickInvite()
    }

    const partnersService = useService(PartnersService)

    const mapSpecialisationsToDropdown = (data = []) => {
        return data.map((item) => ({ id: item.id, label: item.name }));
    };

    const getSpecialisations = () => {
        partnersService.specialisationsList().then((result) =>
            setSpecialisations(mapSpecialisationsToDropdown(result.data))
        )
    }

    useEffect(() => {
        getSpecialisations()
    }, []);

    return(
        <section className="d-flex w-100 pt-1 my-3 align-items-center justify-content-between">

            <div className="d-flex align-items-center gap-4">
                {/*<TableSearch*/}
                {/*    className="biomarkers-search"*/}
                {/*    search={searchProvider?.getValue()}*/}
                {/*    onSearch={searchProvider.setValue}*/}
                {/*    placeholder="Search practitioner's name or specialisation"*/}
                {/*/>*/}

                <TableFilter filterProvider={filterProvider} specialisationOptions={specialisations}/>
            </div>


            <Button color={BUTTON_COLORS.primary} onClick={goToInvite} className="d-flex align-items-center">
                <i className="bx bx-plus me-2"/>
                Invite practitioner
            </Button>
        </section>
    );
};

const ThreeDotDropdown = ({ isOpen, updateIsOpen, options, onSelect }) => {
    return (
        <Dropdown isOpen={isOpen} toggle={() => updateIsOpen(prevState => !prevState)}
                  className="d-inline-block filter-dropdown ms-2">
            <DropdownToggle
                className={joinClassNames("btn btn-transparent no-border no-outline w-fit-content p-0", isOpen && "")}
                tag="button">
                <Icon icon="threeDots" className=""/>
            </DropdownToggle>
            <DropdownMenu end className="three-dot-dropdown w-150 ">
                {options.map(({ label, icon, type, id, disabled, labelClass }) => {
                    return (
                        <DropdownItem disabled={disabled === true} className="three-dot-dropdown__item w-100" key={id}
                                      onClick={() => onSelect(type)}>
                            <Icon icon={icon} className="me-2 three-dot-dropdown__icon"/>
                            <p className={classnames("mb-0", labelClass)}>{label}</p>
                        </DropdownItem>
                    );
                })}
            </DropdownMenu>
        </Dropdown>
    );
};

export const columns = [
    {
        Header: "#",
        width: 20,
        className: "bg-white",
        Cell: (cellProps) => {
            const { params: { offset = 0 } } = useLocationQuery();
            return <label style={{width: "20px"}} className="mb-0 text-nowrap">{cellProps.row.index + 1 + (offset ?? 0)}</label>;
        }
    },
    {
        Header: "Practitioner’s name",
        accessor: "name",
        className: "bg-white",
        canSort: true,
        width: 195,
        Cell: ({row: { original }}) => {
            const { params } = useLocationQuery();
            const searchRequest = useMemo(() => params.search && params.search.toString()?.trim()?.length >= MIN_SEARCH_LENGTH ? params.search : null, [params.search])
            const { decorateText } = useHighlight(searchRequest);
            const fullName = `${original?.firstName} ${original?.lastName}`

            return (
                <section style={{width: "195px", overflow: "hidden"}}>
                    <div {...decorateText(fullName)} className="text-truncate mb-0"/>
                </section>
            );
        }
    },
    {
        Header: 'Email',
        accessor: 'email',
        className: "bg-white",
        width: 195,
        canSort: true,
        Cell: ({ value }) => {
            const { params } = useLocationQuery();
            const { decorateText } = useHighlight(params.search);
            return <div style={{width: "195px", overflow: "hidden"}} className="text-truncate" {...decorateText(value)} />;
        },
    },
    {
        Header: "Specialisation",
        accessor: "specialisation",
        className: "bg-white",
        width: 283,
        Cell: ({row: { original }}) => {
            const specialisationsListNames = original?.userPractitionerSpecialisations?.map((specialisation) => specialisation.name)
            const hiddenSpecialisations = specialisationsListNames?.slice(2)
            return (
                <section style={{width: "283", overflow: "hidden"}} className="d-flex gap-1">
                    {specialisationsListNames?.slice(0, 2).map((specialisation, index) => {
                        return <div
                            key={index}
                            className={classnames(
                                SpecialisationBadge.SpecialisationItem,
                                specialisationsListNames?.length === 1 && SpecialisationBadge.SpecialisationOneItem
                            )}
                        >
                            {specialisation}
                        </div>
                    })}
                    {!!hiddenSpecialisations?.length && <div
                        id={`specialisations-${original.id}`} className={classnames(SpecialisationBadge.SpecialisationItem, SpecialisationBadge.SpecialisationAdditionalItem)}>+{hiddenSpecialisations?.length}</div>}
                    {!!hiddenSpecialisations?.length && <UncontrolledTooltip
                        popperClassName={joinClassNames(
                            "tooltip-alternative-name"
                        )}
                        placement="right"
                        target={`specialisations-${original.id}`}

                    >
                        <ul className="d-flex flex-column align-items-start ps-2 mb-0">
                            {hiddenSpecialisations?.map((specialisations, index) => <li
                                className={joinClassNames(
                                    (index !== hiddenSpecialisations?.length - 1) && "mb-1",
                                    "font-size-13"
                                )}
                                key={index}
                            >
                                {specialisations}
                            </li>)}
                        </ul>
                    </UncontrolledTooltip>}
                </section>
            );
        }
    },
    {
        Header: 'Submission Date',
        accessor: 'submissionDate',
        className: "bg-white",
        width: 133,
        canSort: true,
        Cell: ({row: { original }}) => {
            const value = original?.practitionerAdditionalFields?.submissionDate
            return formatISODate(value);
        },
    },
    {
        Header: 'Status',
        accessor: 'status',
        className: "bg-white",
        canSort: true,
        width: 72,
        Cell: ({row: { original }}) => {
            const value = original?.practitionerAdditionalFields?.status
            return (
                <div className={joinClassNames(statusesPillClasses[value], 'w-fit-content')}>
                    {`${PRACTITIONER_STATUSES_LABELS[value] ?? ''}`}
                </div>
            )
        },
    },
    {
        Header: " ",
        accessor: "actions",
        className: "bg-white",
        width: 40,
        Cell: ({
                   row: {
                       original: { id, practitionerAdditionalFields, ...rest }
                   },
                   actions
               }) => {
            const [isOpen, updateIsOpen] = useState(false);

            const {
                view,
                approve,
                waitlist,
                reject,
                deletePractitioner,
            } = actions;

            const menuActions = {
                [THREE_DOT_TYPES.view]: () => view({id, practitionerAdditionalFields, ...rest}),
                [THREE_DOT_TYPES.approve]: () => approve(id, PRACTITIONER_STATUSES.approved),
                [THREE_DOT_TYPES.waitlist]: () => waitlist(id, PRACTITIONER_STATUSES.waitlisted),
                [THREE_DOT_TYPES.reject]: () => reject(id, PRACTITIONER_STATUSES.rejected),
                [THREE_DOT_TYPES.delete]: () => deletePractitioner(id),
            };

            const optionsByStatus = {
                [PRACTITIONER_STATUSES.pending]: PENDING_ACTIONS,
                [PRACTITIONER_STATUSES.waitlisted]: WAITLISTED_ACTIONS,
                [PRACTITIONER_STATUSES.approved]: APPROVED_REJECTED_ACTIONS,
                [PRACTITIONER_STATUSES.rejected]: APPROVED_REJECTED_ACTIONS,
            }

            const options = optionsByStatus[practitionerAdditionalFields?.status ?? PRACTITIONER_STATUSES.rejected];

            return (
                <section
                    className={joinClassNames("d-flex align-items-center justify-content-center text-primary", isOpen && "force-z-index")}>
                    <ThreeDotDropdown
                        id={id}
                        isOpen={isOpen}
                        updateIsOpen={updateIsOpen}
                        options={options}
                        onSelect={type => {
                            menuActions[type]();
                        }}
                    />
                </section>
            );
        }
    }
]