import Http from "./Http";

class PartnersService extends Http {
    static $displayName = "PartnersService";

    async registerPartner(data) {
        return await this.post(`/partners`, data);
    }

    async specialisationsList() {
        return await this.get(`/partners/specialisations`);
    }

    async addPractitionerProfessionalCredentials(data) {
        return await this.post(`/partners/me/professional-credentials`, data);
    }

    async getPractitionerProfessionalCredentials() {
        return await this.get(`/partners/me/professional-credentials`);
    }

    async addPractiseInfo(data) {
        return await this.post(`/partners/me/practise`, data);
    }

    async getPractiseInfo() {
        return await this.get(`/partners/me/practise`);
    }

    async getPartners(params) {
        return await this.get(`/partners`, { params });
    }

    async sendInvitationEmail(data) {
        return await this.post(`/partners/invite`, data);
    }

    async changePartnerStatus(id, data) {
        return await this.patch(`/partners/${id}/status`, data);
    }

    async getPartnerById(id) {
        return await this.get(`/partners/${id}`);
    }
}

export default PartnersService;