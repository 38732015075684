import React, { useEffect, useMemo, useState } from 'react'
import {IDEAL_TIME_OF_DAY} from "./constants";
import FilterDropdown from "../../Biomarkers/CreateEdit/components/FilterDropdown";
import { keyBy } from "lodash";

export default function TimeOfDayDropdown({
                                           label,
                                           onChange,
                                           value,
                                           error
                                          }) {
    const selectedTimeOfDay = useMemo(() => value?.map(timeOfDay => {
            return {
                ...timeOfDay,
                id: timeOfDay.id,
            }
        }
    ) || [], [value]);

    const [selectedChildrenTimeOfDay, updateSelectedChildrenTimeOfDay] = useState(selectedTimeOfDay);

    const selectedChildrenTimeOfDayById = useMemo(() => keyBy(selectedChildrenTimeOfDay, 'id'), [selectedChildrenTimeOfDay]);

    const handleCheckboxChange = (value, currentState, updateState) => {
        if(currentState.find(({ id }) => value.id === id)) {
            updateState(prevState => prevState.filter(({ id }) => id !== value.id))
            return
        }
        updateState(prevState => [...prevState, value]);
    }

    useEffect(() => {
        onChange(selectedChildrenTimeOfDay.map(({ id }) => id));
    }, [selectedChildrenTimeOfDay]);

    return (
        <FilterDropdown
            label={label}
            value={selectedChildrenTimeOfDay.map(({ label }) => label).join(", ")}
            showPlaceholder={selectedChildrenTimeOfDay.length === 0}
            placeholder="Select the best time of day"
            listOfCheckboxes={IDEAL_TIME_OF_DAY.map((timeOfDay) => {
                return {
                    id: timeOfDay.id,
                    text: timeOfDay.label,
                    value: !!selectedChildrenTimeOfDayById[timeOfDay.id],
                    onChange: () => handleCheckboxChange(timeOfDay, selectedChildrenTimeOfDay, updateSelectedChildrenTimeOfDay)
                }
            })}
            showSelectAllCheckboxes={false}
            selectAllCheckbox={[]}
            clearOption={() => updateSelectedChildrenTimeOfDay([])}
            error={error}
        />
    )
}