import React from "react";
import { useLocation } from 'react-router-dom';
import { AUTH_GROUP_LINKS } from "../config";
import joinClassNames from "../../../base/helpers/joinClassNames";
import HeaderPartner from "../../../base/components/VerticalLayout/HeaderPartner";

function AuthPartnerLayout({ children }) {
    const location = useLocation();

    const CLASS_BY_PATH = {
        [AUTH_GROUP_LINKS.LINK_PARTNER_SIGN_UP]: "auth-partner-layout-sign-in",
    };

    return(
        <>
            <main
                className={joinClassNames(
                    "auth-partner-layout", CLASS_BY_PATH[location.pathname] || "",
                    "h-screen w-screen"
                )}
            >
                <HeaderPartner />
                {children}
            </main>
        </>
    )
}

export default AuthPartnerLayout;