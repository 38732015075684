import Http from "./Http";

class OrganisationsService extends Http {
    static $displayName = "OrganisationsService";

    async getOrganisations(params) {
        return await this.get(`/organisations`, { params });
    }

    async changeStatus(id, newStatus) {
        return this.patch(`/organisations/${id}/status`, { status: newStatus });
    }

    async getCurrentOrganisations() {
        return await this.get(`/organisations/current`);
    }
}

export default OrganisationsService;
