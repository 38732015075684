import React, {useEffect, useState} from "react";
import { Formik } from "formik";

import {useLocation, useNavigate} from "react-router-dom";
import BaseLayoutWithCard from "../../../../base/components/BaseLayoutWithCard";
import {useQueryString} from "../../../../base/hooks/useQueryString";
import {ADMINS_GROUP_LINKS} from "../config";
import {initialValues, validationSchema} from "./form";
import {useService} from "../../../../base/hooks/useService";
import AdminsService from "../../../../services/AdminsService";
import StorageService from "../../../../services/StorageService";
import {KEY_USER} from "../../../../base/constants/storage";
import ToastService from "../../../../services/ToastService";
import FormikInput from "../../../../base/components/FormikInput";
import {MAX_ROLE_NAME_LENGTH} from "./constants";
import joinClassNames from "../../../../base/helpers/joinClassNames";
import Button from "../../../../base/components/Button";
import {BUTTON_COLORS} from "../../../../base/components/Button/appearance";
import {PermissionsTable} from "./PermissionsTable";

export function RoleForm() {
    const { search: locationSearch } = useLocation();
    /**
     * @type {AdminsService}
     */
    const partnersService = useService(AdminsService);
    /**
     * @type {StorageService}
     */
    const storage = useService(StorageService);
    /**
     * @type {ToastService}
     */
    const toastService = useService(ToastService);

    const DEFAULT_OPTIMALLY_ME_ORG = 1;
    const { organisationId } = storage.get(KEY_USER, { organisationId: DEFAULT_OPTIMALLY_ME_ORG });

    const {
        params: {
            editRoleId
        }
    } = useQueryString(locationSearch);

    const navigate = useNavigate();
    const [role, setRole] = useState(null);
    const [isSubmitting, updateIsSubmitting] = useState(false);

    const breadcrumbs = {
        title: editRoleId ? "Edit role" : "Create role",
        breadcrumbItems: [
            { title: "Admins", link: ADMINS_GROUP_LINKS.ROLES_LIST },
            { title: editRoleId ? "Edit role" : "Create role" }
        ]
    };

    const mapRoleToForm = (data) => {
        const editableService = data.filter((service) => service.isEditable)?.map((element) => element?.service.id)
        const viewableService = data.filter((service) => service.isVisible)?.map((element) => element?.service.id)
        return {
            name: data[0]?.roleName ?? "",
            editableServices: editableService ?? [],
            visibleServices: viewableService ?? [],
            isOrganisationAdmin: false
        }
    };

    const mapFormValuesToRole = (data) => {
        return data
    };

    const apiFunction = (role) => {
        if (editRoleId) {
            return partnersService.editRole(organisationId, editRoleId, role);
        }

        return partnersService.createRole(organisationId, role);
    };

    const afterSuccess = () => {
        toastService.success("Role has been successfully saved");
        navigate(ADMINS_GROUP_LINKS.ROLES_LIST);
        updateIsSubmitting(false);
    };

    const createRole = ({ ...otherValues }) => {
        updateIsSubmitting(true);

        apiFunction(mapFormValuesToRole({
            ...otherValues,
        })).then(afterSuccess).finally(() => updateIsSubmitting(false));
    };

    useEffect(() => {
        if (editRoleId) {
            partnersService.getRole(organisationId, editRoleId)
                .then(({data}) => {
                    setRole(mapRoleToForm(data));
                });
        }
    }, [editRoleId, organisationId]);

    return(
        <BaseLayoutWithCard breadcrumbs={breadcrumbs}>
            <Formik
                initialValues={role || initialValues}
                validationSchema={validationSchema}
                validateOnBlur
                onSubmit={createRole}
                enableReinitialize
            >
                {({ errors, handleSubmit, values, setFieldValue, isValid, dirty }) => {
                    return (
                        <form className={joinClassNames("form-horizontal p-2", isSubmitting && "pointer-events-none")}
                              onSubmit={handleSubmit}>
                            <section className="w-50">
                                <FormikInput
                                    placeholder="Give name to a role"
                                    name="name"
                                    maxLength={MAX_ROLE_NAME_LENGTH}
                                    label="Name"
                                    containerClassName="mt-3"
                                />

                                <PermissionsTable
                                    organisationId={organisationId}
                                    visibleServices={values?.visibleServices}
                                    editableServices={values?.editableServices}
                                    setFieldValue={setFieldValue}
                                    errors={errors}
                                />
                            </section>

                            <div className="d-flex justify-content-end mt-5">
                                {<Button
                                    color={BUTTON_COLORS.primary}
                                    type="submit"
                                    disabled={!!Object.keys(errors).length || isSubmitting || !(isValid && dirty)}
                                    loading={isSubmitting}
                                >
                                    Save role
                                </Button>}
                            </div>
                        </form>
                    )
                }}
            </Formik>
        </BaseLayoutWithCard>
    )
}