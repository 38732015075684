import React from "react";

export default function GoogleCaptchaLinks() {
    return (
        <div className="partner-sign-up-google-captcha">
            This site is protected by reCAPTCHA and the Google
            <a
                href="https://policies.google.com/privacy?hl=en"
                target="_blank"
                rel="noopener noreferrer"
                className="auth-partner-link mx-1">Privacy Policy</a> and{" "}
            <a
                href="https://policies.google.com/terms?hl=en"
                target="_blank"
                rel="noopener noreferrer"
                className="auth-partner-link mx-1"
            >Terms of Service</a>
            {" "}apply.
        </div>
    )
}