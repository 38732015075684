export const MIN_ROLE_NAME_LENGTH = 1;
export const MAX_ROLE_NAME_LENGTH = 50;

export const PERMISSIONS_ACTIONS = {
    VIEW: 'VIEW',
    EDIT_CREATE: 'EDIT_CREATE',
    ALL_VIEW: 'ALL_VIEW',
    ALL_EDIT_CREATE: 'ALL_EDIT_CREATE'
};

export const SELECT_ALL_ID = "select_all"

export const SELECT_ALL_ELEMENT = {
    name: "Select all",
    id: SELECT_ALL_ID
}