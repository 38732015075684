import React, {useEffect, useMemo, useState} from "react";
import {useService} from "../../../../base/hooks/useService";
import ConfigService from "../../../../services/ConfigService";
import {useLoading} from "../../../../base/hooks/useLoading";
import Table from "../../../../base/components/Table";
import {useLocationQuery} from "../../../../base/hooks/useQueryString";
import {useHighlight} from "../../../../base/hooks/useHighlight";
import {PERMISSIONS_ACTIONS, SELECT_ALL_ELEMENT, SELECT_ALL_ID} from "./constants";
import Checkbox from "../../../../base/components/Checkbox";
import PropTypes from "prop-types";

function NoRolesPermissions() {
    return <div className="text-center">
        No permissions for now.
    </div>
}

export function PermissionsTable({
                                     organisationId,
                                     setFieldValue,
                                     visibleServices,
                                     editableServices,
                                     errors
                                 }) {
    /**
     * @type {ConfigService}
     */
    const configService = useService(ConfigService);
    const [isLoading, { registerPromise }] = useLoading(true);
    const [services, setServices] = useState(null);

    const onClear = () => {
        setFieldValue("editableServices", []);
        setFieldValue("visibleServices", [])
    }

    const onChangeVisibleServices = (id, value) => {
        if (value) {
            setFieldValue("visibleServices", [
                ...visibleServices,
                id
            ])
        } else {
            const updatedVisibleServices = visibleServices.filter((service) => service !== id)
            setFieldValue("visibleServices", updatedVisibleServices)
        }
    }

    const getServicesIds = (services) => {
        return services?.map((service) => service.id)
    }

    const onChangeSelectAllVisibleServices = (id, value) => {
        if (value) {
            setFieldValue("visibleServices", getServicesIds(services))
        } else {
            setFieldValue("visibleServices", [])
        }
    }

    const onChangeEditableServices = (id, value) => {
        if (value) {
            setFieldValue("editableServices", [
                ...editableServices,
                id
            ])
        } else {
            const updatedEditableServices = editableServices.filter((service) => service !== id)
            setFieldValue("editableServices", updatedEditableServices)
        }
    }

    const onChangeSelectAllEditableServices = (id, value) => {
        if (value) {
            setFieldValue("editableServices", getServicesIds(services))
        } else {
            setFieldValue("editableServices", [])
        }
    }

    const columns = useMemo(() => {
        return [
            {
                Header: "Action",
                accessor: "name",
                className: "bg-white",
                canSort: false,
                Cell: ({ value }) => {
                    const { params } = useLocationQuery();
                    const { decorateText } = useHighlight(params.search);

                    return (
                        <section className="w-fit-content d-flex align-items-center">
                            <div {...decorateText(value)} className="text-truncate mb-0" style={{ maxWidth: "33vw" }}/>
                        </section>
                    );
                }
            },
            {
                Header: "View",
                className: "bg-white text-center",
                headerClassName: "text-center",
                width: 100,
                Cell: ({
                           row: {
                               original: { id }
                           },
                           actions
                       }) => {

                    return <section
                        className="d-flex gap-1 align-items-end justify-content-center"
                        onClick={(event) => {
                            event.stopPropagation();
                        }}
                    >
                        <Checkbox
                            value={id !== SELECT_ALL_ID ? visibleServices?.includes(id) : visibleServices && (visibleServices?.length === services?.length)}
                            onChange={(event) => {
                                event.stopPropagation();
                                const value = event.target.checked
                                if (id === SELECT_ALL_ID) {
                                    actions[PERMISSIONS_ACTIONS.ALL_VIEW](id, value);
                                } else {
                                    actions[PERMISSIONS_ACTIONS.VIEW](id, value);
                                }
                            }}
                        />
                    </section>;
                }
            },
            {
                Header: "Edit / Create",
                className: "bg-white text-center",
                headerClassName: "text-center",
                width: 100,
                Cell: ({
                           row: {
                               original: { id }
                           },
                           actions
                       }) => {

                    return <section
                        className="d-flex gap-1 align-items-end justify-content-center"
                        onClick={(event) => {
                            event.stopPropagation();
                        }}
                    >
                        <Checkbox
                            value={id !== SELECT_ALL_ID ? editableServices?.includes(id) : editableServices && (editableServices?.length === services?.length)}
                            onChange={(event) => {
                                event.stopPropagation();
                                const value = event.target.checked
                                if (id === SELECT_ALL_ID) {
                                    actions[PERMISSIONS_ACTIONS.ALL_EDIT_CREATE](id, value);
                                } else {
                                    actions[PERMISSIONS_ACTIONS.EDIT_CREATE](id, value);
                                }

                            }}
                        />
                    </section>;
                }
            }
        ]
    }, [visibleServices, editableServices])

    const getServices = () => {
        registerPromise(configService.fetchServiceSettings(
            organisationId,
            { isEnabled: true }
            )
            .then(({ data }) => {
                setServices(data);
        }));
    }

    useEffect(() => {
        getServices()
    }, [])

    return(
        <section className="mt-5">
            <div className="mb-1 d-flex justify-content-between">
                <label>Set permissions</label>
                <button type="button"
                        className="text-danger border-0 bg-transparent"
                        onClick={onClear}>
                    Clear
                </button>
            </div>
            {errors?.visibleServices && <span className="text-danger">{errors?.visibleServices}</span>}
            <Table
                columns={services?.length ? columns : []}
                data={!!services?.length > 0 ? [SELECT_ALL_ELEMENT, ...services] : []}
                loading={isLoading}
                commonPlaceholder={<NoRolesPermissions/>}
                isRowClick={false}
                actions={{
                    [PERMISSIONS_ACTIONS.VIEW]: onChangeVisibleServices,
                    [PERMISSIONS_ACTIONS.EDIT_CREATE]: onChangeEditableServices,
                    [PERMISSIONS_ACTIONS.ALL_VIEW]: onChangeSelectAllVisibleServices,
                    [PERMISSIONS_ACTIONS.ALL_EDIT_CREATE]: onChangeSelectAllEditableServices,
                }}
                tableClassName="overflow-x-visible"
                withoutPagination
            />
        </section>
    )
}

PermissionsTable.propTypes = {
    organisationId: PropTypes.number,
    setFieldValue: PropTypes.func,
    visibleServices: PropTypes.array,
    editableServices: PropTypes.array,
    errors: PropTypes.object,
}