import React, {useCallback, useEffect, useState} from "react";
import {Formik} from "formik";
import { Country, City }  from 'country-state-city';
import { Typeahead } from 'react-bootstrap-typeahead';

import AuthPartnerLayout from "../../../../components/AuthPartnerLayout";
import {validationSchema} from "./form";
import joinClassNames from "../../../../../../base/helpers/joinClassNames";
import {useService} from "../../../../../../base/hooks/useService";
import PartnersService from "../../../../../../services/PartnersService";
import ToasterService from "../../../../../../services/ToastService";
import {useLoading} from "../../../../../../base/hooks/useLoading";
import {useNavigate} from "react-router-dom";
import {BUTTON_COLORS} from "../../../../../../base/components/Button/appearance";
import Button from "../../../../../../base/components/Button";
import FormikInput from "../../../../../../base/components/FormikInput";
import {MAX_CLINIC_NAME_LENGTH} from "./constants";
import classnames from "classnames";
import {AUTH_GROUP_LINKS} from "../../../../config";

const defaultCountry = {
    isoCode: "GB",
    name: "United Kingdom"
}

const initialValues = {
    clinicName: "",
    country: [defaultCountry],
    city: "",
    address: "",
    website: ""
}

const Step2 = () => {
    /**
     * @type {PartnersService}
     */
    const partnersService = useService(PartnersService);
    /**
     * @type {ToasterService}
     */
    const toastService = useService(ToasterService);
    const [isLoading, {registerPromise}] = useLoading();
    const navigate = useNavigate();

    const [requestError, setRequestError] = useState(false);
    const [currentPractiseInfo, setCurrentPractiseInfo] = useState(null)

    const onAddPractiseInfo = (values, formikHelpers) => {
        formikHelpers.setSubmitting(true);

        const model = {
            ...values,
            country: values.country[0].name,
            city: values.city[0].name,
        }

        registerPromise(partnersService.addPractiseInfo(model))
            .then(() => {
                navigate(AUTH_GROUP_LINKS.LINK_PARTNER_VERIFICATION_IN_PROGRESS);
            })
            .catch((error) => {
                toastService.error(error.message);
            })
            .finally(() => {
                formikHelpers.setSubmitting(false);
            })
    }

    const getCurrentPractiseInfo = useCallback( () => {
        registerPromise(partnersService.getPractiseInfo())
            .then((data) => {
                const {clinicName, country: countryRequest, city: cityRequest, address, website} = data;
                if (!clinicName) return;

                const currentCountry = Country.getAllCountries().find(country => country?.name === countryRequest);
                const citiesList = currentCountry ? City.getCitiesOfCountry(currentCountry.isoCode) : City.getAllCities();
                const currentCity = citiesList.find(city => city?.name === cityRequest);

                setCurrentPractiseInfo({
                    clinicName,
                    address: address ?? "",
                    website: website ?? "",
                    country: currentCountry ? [currentCountry] : [],
                    city: currentCity ? [currentCity] : []
                });
            })
            .catch((error) => {
                toastService.error(error.message);
                setRequestError(error)
            })
    }, [setCurrentPractiseInfo]);

    useEffect(() => {
        getCurrentPractiseInfo()
    }, [])

    return(
        <AuthPartnerLayout>
            <div className="w-100 d-flex align-items-center justify-content-center">
                <div className="partner-step-layout">
                    <div>
                        <h1 className="partner-step-title">
                            <span className="me-3">2
                                <span className="partner-text-grey font-size-16 me-2">/2</span>
                            </span>
                            Tell us about your practice
                        </h1>
                    </div>
                    <p className="partner-text-grey font-size-18 mt-2 mb-0">
                        Let us know how clients will find you (optional).
                    </p>
                    <div className="mt-4-5">
                        <Formik
                            initialValues={currentPractiseInfo?.clinicName?.length ? currentPractiseInfo : initialValues}
                            validationSchema={validationSchema}
                            validateOnChange
                            onSubmit={(values, formikHelpers) => onAddPractiseInfo(values, formikHelpers)}
                            enableReinitialize
                        >
                            {({errors, touched, handleSubmit, values, setFieldValue, setFieldTouched, isSubmitting, isValid, dirty}) => {
                                return (
                                    <form
                                        className={joinClassNames("form-horizontal", isSubmitting && "pointer-events-none")}
                                        onSubmit={handleSubmit}>
                                        <FormikInput
                                            id="clinicName"
                                            name="clinicName"
                                            label="Clinic name"
                                            containerClassName="relative mb-3"
                                            placeholder="Enter clinic name or name of practice name"
                                            type="text"
                                            withReset
                                            afterOnChange={() => setRequestError(false)}
                                            maxLength={MAX_CLINIC_NAME_LENGTH}
                                        />
                                        <div className="w-100 mb-3 gap-3 d-flex">
                                            <div className="w-50">
                                                <label>Country</label>
                                                <Typeahead
                                                    id="country"
                                                    placeholder="Start typing country"
                                                    onChange={(selected) => {
                                                        setFieldTouched("country", true)
                                                        setFieldValue("country", selected)
                                                        setRequestError(false)
                                                    }}
                                                    options={Country.getAllCountries()}
                                                    selected={values.country}
                                                    filterBy={['name']}
                                                    labelKey="name"
                                                    defaultSelected={[{name: "United Kingdom"}]}
                                                    className={classnames(errors.country?.length && touched?.country && "typeahead-invalid is-invalid without-error-icon")}
                                                />
                                                {errors.country?.length && touched?.country &&
                                                <span className="invalid-feedback d-block">
                                                    {errors.country}
                                                </span>}
                                            </div>
                                            <div className="w-50">
                                                <label>City</label>
                                                <Typeahead
                                                    id="city"
                                                    placeholder="Start typing city"
                                                    onChange={(selected) => {
                                                        setFieldTouched("city", true)
                                                        setFieldValue("city", selected)
                                                        setRequestError(false)
                                                    }}
                                                    options={values.country?.length ? City.getCitiesOfCountry(values.country[0]?.isoCode ?? defaultCountry.isoCode) : City.getAllCities()}
                                                    selected={values.city}
                                                    filterBy={['name']}
                                                    labelKey="name"
                                                    className={classnames(errors.city?.length && touched?.city && "typeahead-invalid is-invalid without-error-icon")}
                                                />
                                                {errors.city?.length && touched?.city &&
                                                <span className="invalid-feedback d-block">
                                                    {errors.city}
                                                </span>}
                                            </div>
                                        </div>
                                        <FormikInput
                                            className="typeahead"
                                            id="address"
                                            name="address"
                                            label="Address"
                                            containerClassName="relative mb-3"
                                            placeholder="Enter address"
                                            type="text"
                                            withReset
                                            afterOnChange={() => setRequestError(false)}
                                            maxLength={MAX_CLINIC_NAME_LENGTH}
                                        />
                                        <FormikInput
                                            id="website"
                                            name="website"
                                            label="Website"
                                            containerClassName="relative"
                                            placeholder="Enter website address"
                                            type="text"
                                            withReset
                                            afterOnChange={() => setRequestError(false)}
                                            maxLength={MAX_CLINIC_NAME_LENGTH}
                                        />
                                        <Button
                                            color={BUTTON_COLORS.primary}
                                            type="submit"
                                            loading={isLoading || isSubmitting}
                                            className="sign-up-submit mt-4-5 w-100 mb-5"
                                            disabled={
                                                isLoading || requestError ||
                                                !(isValid && dirty)
                                            }
                                        >
                                            Send to verify
                                        </Button>
                                    </form>
                            )}}
                        </Formik>
                    </div>
                </div>
            </div>
        </AuthPartnerLayout>
    )
}

export default Step2;