import React, {useEffect, useMemo, useState} from "react";
import {ModalFooter, Spinner, UncontrolledTooltip} from "reactstrap";
import classnames from "classnames";

import {CustomModal, ModalBody, ModalHeader} from "../../../../../base/components/CustomModal";
import {useService} from "../../../../../base/hooks/useService";
import PartnersService from "../../../../../services/PartnersService";
import ToasterService from "../../../../../services/ToastService";
import {useLoading} from "../../../../../base/hooks/useLoading";
import {PRACTITIONER_STATUSES, PRACTITIONER_STATUSES_LABELS} from "../../constants";
import Button from "../../../../../base/components/Button";
import {BUTTON_COLORS} from "../../../../../base/components/Button/appearance";
import joinClassNames from "../../../../../base/helpers/joinClassNames";
import {statusesPillClasses} from "../components";
import ValueWithLabel from "../../../CustomerManagement/Details/ValueWIthLabel";
import Icon from "../../../../../base/components/Icon";
import {BYTES_IN_KILOBYTE} from "../../../../../base/constants/shared";
import Table from "../../../../../base/components/Table";
import {columns} from "./components";

import SpecialisationBadge from "../SpecialisationBadge.module.scss";
import classes from "./PractitionersDetailsModal.module.scss";

const ACTIVE_ACTIONS_STATE = [
    PRACTITIONER_STATUSES.waitlisted,
    PRACTITIONER_STATUSES.pending
]

const PractitionersDetailsModal = ({
    updateIsOpen,
    isOpen,
    row,
    afterOnClose
}) => {
    const { id, firstName, lastName } = row;
    /**
     * @type {PartnersService}
     */
    const partnersService = useService(PartnersService);
    /**
     * @type {ToasterService}
     */
    const toastService = useService(ToasterService);

    const [isLoading, { registerPromise }] = useLoading(true);
    const [practitioner, updatePractitioner] = useState(null);

    const getDetails = () => {
        registerPromise(partnersService.getPartnerById(id))
            .then((data) => {
                updatePractitioner(data)
            })
            .catch((error) => {
                toastService.error(error.message)
            })
    }

    let files = useMemo(() => {
        let currentFiles = []
        practitioner?.practitionerProfessionalCredentials?.map((practitionerProfessionalCredential) => {
            practitionerProfessionalCredential?.files.map((file) => {
                currentFiles.push(file)
            })
        })
        return currentFiles
    }, [practitioner])

    const changeStatus = (status) => {
        partnersService.changePartnerStatus(id, {status})
            .then(() => {
                getDetails(id)
                afterOnClose();
                toastService.success("The practitioners status has been successfully changed")
            })
            .catch((error) => {
                toastService.error(error.message)
            })
    }

    const onClose = () => {
        updateIsOpen(false);
    }

    const downloadURI = (url, name) => {
        fetch(url, {method: "get"})
            .then((res) => res.blob())
            .then((res) => {
                const aElement = document.createElement("a");
                aElement.setAttribute("download", name);
                const href = URL.createObjectURL(res);
                aElement.href = href;
                aElement.setAttribute("target", "_blank");
                aElement.click();
                URL.revokeObjectURL(href);
            });
    }

    useEffect(() => {
       if (!id) return;
       getDetails(id)
    }, [])

    return(
        <CustomModal isOpen={isOpen} className="hl7-modal practitioner-modal fontStyles" toggle={onClose}>
            <ModalHeader onClose={onClose} className="text-dark">
                {firstName} {lastName}
            </ModalHeader>

            <ModalBody className="pb-0">
                {isLoading ? <section className="d-flex justify-content-center my-3">
                        <Spinner color="info" className="mx-auto"/>
                    </section>
                : <div className="mb-3">
                        <h2 className="font-size-15 font-weight-semibold mb-3">Personal Details</h2>
                        <section className='d-flex p-relative gap-3 mb-4'>
                            <ValueWithLabel
                                value={practitioner.email}
                                label="Email"
                                className={"w-1-3"}
                            />
                            <ValueWithLabel
                                value={practitioner?.practitionerAdditionalFields?.phone}
                                label="Phone"
                                className={"w-1-3"}
                            />
                            <ValueWithLabel
                                value={<div className={joinClassNames(statusesPillClasses[practitioner?.practitionerAdditionalFields?.status], 'w-fit-content')}>
                                    {`${PRACTITIONER_STATUSES_LABELS[practitioner?.practitionerAdditionalFields?.status] ?? ''}`}
                                </div>}
                                label="Status"
                                className={"w-1-3"}
                            />
                        </section>
                        <h2 className="font-size-15 font-weight-semibold mb-3">Professional Credentials</h2>
                        <Table
                            columns={practitioner?.practitionerProfessionalCredentials?.length ? columns : []}
                            data={practitioner?.practitionerProfessionalCredentials ?? []}
                            loading={isLoading}
                            withoutPagination
                            tableClassName={classes.Table}
                        />
                        <h2 className="font-size-15 font-weight-semibold mb-3 mt-2">Practice Information</h2>
                        <section className='d-flex p-relative gap-3 mb-4'>
                            <ValueWithLabel
                                value={practitioner?.practitionerAdditionalFields?.clinicName ?? '-'}
                                label="Clinic name"
                                className={"w-1-3"}
                            />
                            <ValueWithLabel
                                value={`${practitioner?.practitionerAdditionalFields?.address  ?? '-'}, ${practitioner?.practitionerAdditionalFields?.city  ?? '-'}, ${practitioner?.practitionerAdditionalFields?.country ?? '-'} `}
                                label="Location"
                                className={"w-1-3"}
                            />
                            <ValueWithLabel
                                value={`${practitioner?.practitionerAdditionalFields?.website ?? '-'}`}
                                label="Website"
                                className={"w-1-3"}
                            />
                        </section>
                        <h2 className="font-size-15 font-weight-semibold mb-3">Service Offerings</h2>
                        <section className='d-flex p-relative gap-1 mb-4'>
                            {practitioner?.userPractitionerSpecialisations?.map((specialisations, index) => {
                                return <div
                                    key={index}
                                    className={classnames(
                                        SpecialisationBadge.SpecialisationItem,
                                        SpecialisationBadge.SpecialisationFullItem,
                                        "cursor-pointer"
                                    )}
                                >
                                    <span id={`specialisations-${index}`}>{specialisations.name}</span>
                                    <UncontrolledTooltip
                                        popperClassName={joinClassNames(
                                            "tooltip-alternative-name"
                                        )}
                                        placement="bottom"
                                        target={`specialisations-${index}`}

                                    >
                                        {specialisations.name}
                                    </UncontrolledTooltip>
                                </div>
                            })}
                        </section>
                        <h2 className="font-size-15 font-weight-semibold mb-3">Uploaded Documents</h2>
                        {!!files?.length && <div className={classes.FilesContainer}>
                            {files?.map((file, index) => {
                                return <div key={index} className={classnames(classes.FileItem, "d-flex gap-3")}>
                                    <div className="d-flex align-items-center">
                                        <Icon width={24} height={24} icon="practitionersFile" className="me-3"/>
                                        <div className="d-flex flex-column">
                                            <div className="mb-1 text-secondary">{file.name}</div>
                                            <div className="font-weight-semibold">{`${(file.bytes / BYTES_IN_KILOBYTE).toFixed(2)} KB`}</div>
                                        </div>
                                    </div>
                                    <div className="d-flex align-items-center gap-2">
                                        <Icon width={24} height={24} icon="practitionersEyeFile" className="cursor-pointer" onClick={() => window.open(file.link, '_blank')} />
                                        <Icon width={24} height={24} icon="practitionersDownloadFile" className="cursor-pointer" onClick={() => downloadURI(file.link, file.name)} />
                                    </div>
                                </div>
                            })}
                        </div>}
                    </div>}
            </ModalBody>
            {ACTIVE_ACTIONS_STATE.includes(practitioner?.practitionerAdditionalFields?.status) && <ModalFooter>
                <div className="d-flex justify-content-end gap-2">
                    {practitioner?.practitionerAdditionalFields?.status === PRACTITIONER_STATUSES.pending && <Button
                        color={BUTTON_COLORS.primaryOutline}
                        type="button"
                        onClick={() => changeStatus(PRACTITIONER_STATUSES.waitlisted)}
                    >
                        Waitlist
                    </Button>}

                    <Button
                        color={BUTTON_COLORS.danger}
                        type="button"
                        onClick={() => changeStatus(PRACTITIONER_STATUSES.rejected)}
                    >
                        Reject
                    </Button>

                    <Button
                        color={BUTTON_COLORS.primary}
                        type="button"
                        onClick={() => changeStatus(PRACTITIONER_STATUSES.approved)}
                    >
                        Approve
                    </Button>
                </div>
            </ModalFooter>}
        </CustomModal>
    )
}

export default PractitionersDetailsModal