import Http from "./Http";
import axios from "axios";
import {
  FOODINTOLERANCE_FILE_TYPE
} from "../base/constants/shared";

class S3Service extends Http {
  static $displayName = "S3Service";

  /**
   * Sending request on adding file to S3
   * fileType is a constants variable
   * */
  requestLoading(model, abortSignal) {
    return this.post("/files", model, {signal: abortSignal});
  }

  /**
   * Direct S3 file loading
   * */
  uploadToS3(model, abortSignal) {
    const { data, file } = model;
    const formData = new FormData();

    Object.keys(data.meta.formData).forEach((key) => {
      formData.append(key, data.meta.formData[key].toString());
    });

    formData.append("file", file);

    return axios.post(data.meta.url, formData, {signal: abortSignal}).then(() => data.data)
    // this.post(data.meta.url, formData).then(() => data.data);
  }

  /**
   * Simplified image uploading process
   * */

  // TODO use one of these methods:
  //  - uploadImageFiles
  //  - uploadImageFile
  async uploadImageFiles(files) {
    const promises = files.map(async (file) => {
      const { data } = await this.requestLoading([{ contentType: file.type }]);
      await this.uploadToS3({ data: data[0], file });

      return data[0];
    });

    return Promise.all(promises);
  }

  async uploadImageFile(file, type, abortSignal, originalName) {
    const data = await this.requestLoading({ files: [{ contentType: file.type, type, ...originalName?.length && {originalName} }] }, abortSignal);
    const firstFile = data.files[0];
    await this.uploadToS3({ data: firstFile, file }, abortSignal );
    return firstFile;
  }

  async uploadFoodCsv(file, abortSignal) {
    const type = FOODINTOLERANCE_FILE_TYPE;
    const data = await this.requestLoading({ files: [{ contentType: file.type, type }] }, abortSignal);
    const firstFile = data.files[0];
    await this.uploadToS3({ data: firstFile, file }, abortSignal);
    return firstFile;
  }
}

export default S3Service;
