import React, {useCallback, useEffect, useState} from "react";
import {FieldArray, Formik} from "formik";

import AuthPartnerLayout from "../../../../components/AuthPartnerLayout";
import joinClassNames from "../../../../../../base/helpers/joinClassNames";
import {validationSchema} from "./form";
import {useLoading} from "../../../../../../base/hooks/useLoading";
import {useService} from "../../../../../../base/hooks/useService";
import PartnersService from "../../../../../../services/PartnersService";
import {BUTTON_COLORS} from "../../../../../../base/components/Button/appearance";
import Button from "../../../../../../base/components/Button";
import {
    FILES_MAX_COUNT,
    MAX_CREDENTIALS_COUNT,
    NAME_MAX_LENGTH,
    PARTNER_DATE_OUTPUT_FORMAT,
    REFERENCE_NUMBER_MAX_LENGTH
} from "./constants";
import classnames from "classnames";
import classNames from "classnames";
import Icon from "../../../../../../base/components/Icon";
import FormikInput from "../../../../../../base/components/FormikInput";
import Checkbox from "../../../../../../base/components/Checkbox";
import {CustomDatePicker} from "../../../../../../base/components/Datepicker";
import {DEFAULT_DATE} from "../../../../../../base/constants/date";
import UploadProfessionalDocuments from "./UploadProfessionalDocuments/UploadProfessionalDocuments";
import classes from "./UploadProfessionalDocuments/UploadProffesionalDocuments.module.scss";
import {formatBytes} from "./helpers";
import {DEFAULT_DATE_FORMAT_FOR_BACKEND, formatJSDate} from "../../../../../../base/helpers/date";
import {AUTH_GROUP_LINKS} from "../../../../config";
import {useNavigate} from "react-router-dom";
import ToasterService from "../../../../../../services/ToastService";

const initialCredentialsValues = {
    name: "",
    issuingOrganisation: "",
    referenceNumber: "",
    issueDate: "",
    expirationDate: "",
    doesntExpire: false,
    files: []
}

const initialValues = {
    credentials: [initialCredentialsValues]
}

const Step1 = () => {
    /**
     * @type {PartnersService}
     */
    const partnersService = useService(PartnersService);
    /**
     * @type {ToasterService}
     */
    const toastService = useService(ToasterService);
    const [isLoading, {registerPromise}] = useLoading();
    const navigate = useNavigate();

    const [requestError, setRequestError] = useState(false);
    const [currentProfessionalDocuments, setCurrentProfessionalDocuments] = useState(null)
    const [openUploadDocument, updateOpenUploadDocument] = useState();

    const onAddProfessionalCredentials = async (values, formikHelpers) => {
        try {
            formikHelpers.setSubmitting(true);

            const transformedValues = values.credentials.map(({ issueDate, expirationDate, doesntExpire, files, referenceNumber, ...rest }) => {
                const formattedCredential = {
                    ...rest,
                    referenceNumber,
                    ...(issueDate && { issueDate: formatJSDate(issueDate, DEFAULT_DATE_FORMAT_FOR_BACKEND) }),
                    ...(expirationDate && !doesntExpire && { expirationDate: formatJSDate(expirationDate, DEFAULT_DATE_FORMAT_FOR_BACKEND) }),
                    files: files.map(({ id }) => id),
                };

                if (!formattedCredential.expirationDate) delete formattedCredential.expirationDate;
                if (!referenceNumber?.length) delete formattedCredential.referenceNumber;

                return formattedCredential;
            });

            await registerPromise(partnersService.addPractitionerProfessionalCredentials({ credentials: transformedValues }));
            navigate(AUTH_GROUP_LINKS.LINK_PARTNER_STEP_2);
        } catch (error) {
            toastService.error(error.message);
        } finally {
            formikHelpers.setSubmitting(false);
        }
    };

    const deleteDocument = (name, currentFileValues, indexDelete, setFieldValue) => {
        const filteredFiles = currentFileValues.filter((_, i) => i !== indexDelete);
        setFieldValue(name, filteredFiles);
    }

    const getCurrentProfessionalCredentials = useCallback(async () => {
        try {
            const { data } = await registerPromise(partnersService.getPractitionerProfessionalCredentials());

            const transformedData = data?.map(({ name = "", issuingOrganisation = "", referenceNumber = "", issueDate, expirationDate, files = [] }) => ({
                name,
                issuingOrganisation,
                referenceNumber,
                issueDate: issueDate ? new Date(issueDate) : "",
                expirationDate: expirationDate ? new Date(expirationDate) : "",
                doesntExpire: !expirationDate,
                files,
            }));

            setCurrentProfessionalDocuments({ credentials: transformedData });
        } catch (error) {
            setRequestError(true);
            toastService.error(error.message);
        }
    }, [setCurrentProfessionalDocuments]);

    useEffect(() => {
        getCurrentProfessionalCredentials()
    }, [])

    return (
        <AuthPartnerLayout>
            <div className="w-100 d-flex align-items-center justify-content-center">
                <div className="partner-step-layout">
                    <div>
                        <h1 className="partner-step-title">
                            <span className="me-3">1
                                <span className="partner-text-grey font-size-16 me-2">/2</span>
                            </span>
                            Showcase professional credentials
                        </h1>
                    </div>
                    <p className="partner-text-grey font-size-18 mt-2 mb-0">
                        To join OptimallyMe Pro, you must be qualified or a current student. Indicate and upload your
                        certifications, licenses, and any regulatory memberships to verify you professional credentials
                        (e.g., RD, CPT).
                    </p>
                    <div className="mt-4-5">
                        <Formik
                            initialValues={currentProfessionalDocuments?.credentials?.length ? currentProfessionalDocuments : initialValues}
                            validationSchema={validationSchema}
                            validateOnChange
                            onSubmit={(values, formikHelpers) => onAddProfessionalCredentials(values, formikHelpers)}
                            enableReinitialize
                        >
                            {({errors, touched, handleSubmit, values, setFieldValue, setFieldTouched, isSubmitting, isValid, dirty}) => {
                                return (
                                    <form
                                        className={joinClassNames("form-horizontal", isSubmitting && "pointer-events-none")}
                                        onSubmit={handleSubmit}>
                                        <FieldArray name={`credentials`}>
                                            {({push, remove}) => {
                                                return (
                                                    <div>
                                                        {values.credentials.map((credential, index) => {
                                                            return <div key={index}
                                                                        className={classnames("partner-credentials-item", index !== 0 && "mt-4-5")}>
                                                                <div
                                                                    className="d-flex justify-content-between align-items-center partner-credentials-title-block">
                                                                    <h2 className="font-size-20 partner-credential-title mb-0">
                                                                        {`Professional credential #${index + 1}`}
                                                                    </h2>
                                                                    {index === 0 &&
                                                                    <div className="partner-text-grey">*required</div>
                                                                    }
                                                                    {index > 0 && <div
                                                                        className="d-flex align-items-center cursor-pointer partner-text-red font-weight-semibold"
                                                                        onClick={
                                                                            () => {
                                                                                remove(index);
                                                                            }
                                                                        }>
                                                                        <Icon icon="partnerTrash"
                                                                              width={20}
                                                                              height={20}
                                                                              className="me-2"
                                                                        />
                                                                        <div>
                                                                            Remove
                                                                        </div>
                                                                    </div>}
                                                                </div>
                                                                <div className="mt-4 partner-credentials-form">
                                                                    <FormikInput
                                                                        id={`credentials.${index}.name`}
                                                                        name={`credentials.${index}.name`}
                                                                        label="Name"
                                                                        containerClassName="relative w-100 mb-3"
                                                                        placeholder="Enter license/certification/membership name"
                                                                        type="text"
                                                                        withReset
                                                                        afterOnChange={() => setRequestError(false)}
                                                                        maxLength={NAME_MAX_LENGTH}
                                                                    />
                                                                    <div className="d-flex w-100 gap-3 mb-3">
                                                                        <FormikInput
                                                                            id={`credentials.${index}.issuingOrganisation`}
                                                                            name={`credentials.${index}.issuingOrganisation`}
                                                                            label="Issuing organisation"
                                                                            containerClassName="relative w-50"
                                                                            placeholder="Enter issuing organisation name"
                                                                            type="text"
                                                                            withReset
                                                                            afterOnChange={() => setRequestError(false)}
                                                                            maxLength={NAME_MAX_LENGTH}
                                                                        />
                                                                        <FormikInput
                                                                            id={`credentials.${index}.referenceNumber`}
                                                                            name={`credentials.${index}.referenceNumber`}
                                                                            label="ID or reference number (if applicable)"
                                                                            containerClassName="relative w-50"
                                                                            placeholder="0000000"
                                                                            type="text"
                                                                            withReset
                                                                            afterOnChange={() => setRequestError(false)}
                                                                            maxLength={REFERENCE_NUMBER_MAX_LENGTH}
                                                                        />
                                                                    </div>
                                                                    <div className="d-flex w-100 gap-3 mb-3">
                                                                        <div
                                                                            className={classnames(values.credentials[index].doesntExpire ? "partner-single-datepicker" : "w-50")}>
                                                                            <label>Issue date</label>
                                                                            <CustomDatePicker
                                                                                placeholder={'DD / MM / YYYY'}
                                                                                date={values.credentials[index].issueDate}
                                                                                onChange={(value) => {
                                                                                    setFieldTouched(`credentials.${index}.issueDate`, true, true)
                                                                                    setFieldValue(`credentials.${index}.issueDate`, value)
                                                                                    setRequestError(false)
                                                                                }}
                                                                                className="w-100"
                                                                                showIcon={false}
                                                                                inputClassName={classnames(errors.credentials?.[index]?.issueDate?.length && touched.credentials?.[index]?.issueDate && "form-control is-invalid without-error-icon")}
                                                                                dateFormat={PARTNER_DATE_OUTPUT_FORMAT}
                                                                            />
                                                                            {errors.credentials?.[index]?.issueDate?.length && touched.credentials?.[index]?.issueDate &&
                                                                            <span className="invalid-feedback d-block">
                                                                            {errors.credentials?.[index]?.issueDate}
                                                                        </span>}
                                                                        </div>
                                                                        {!values.credentials[index].doesntExpire &&
                                                                        <div className="w-50">
                                                                            <label>Expiration date</label>
                                                                            <CustomDatePicker
                                                                                placeholder={'DD / MM / YYYY'}
                                                                                date={values.credentials[index].expirationDate}
                                                                                onChange={(value) => {
                                                                                    setFieldTouched(`credentials.${index}.expirationDate`, true, true)
                                                                                    setFieldValue(`credentials.${index}.expirationDate`, value)
                                                                                    setRequestError(false)
                                                                                }}
                                                                                className="w-100"
                                                                                showIcon={false}
                                                                                dateFormat={PARTNER_DATE_OUTPUT_FORMAT}
                                                                                maxDate=""
                                                                                minDate={DEFAULT_DATE}
                                                                                inputClassName={classnames(errors.credentials?.[index]?.expirationDate?.length && touched.credentials?.[index]?.expirationDate && "form-control is-invalid without-error-icon")}
                                                                            />
                                                                            {errors.credentials?.[index]?.expirationDate?.length && touched.credentials?.[index]?.expirationDate &&
                                                                            <span className="invalid-feedback d-block">
                                                                            {errors.credentials?.[index]?.expirationDate}
                                                                        </span>}
                                                                        </div>}
                                                                    </div>
                                                                    <Checkbox
                                                                        name={`credentials.${index}.doesntExpire`}
                                                                        text="Does not expire"
                                                                        value={values.credentials[index].doesntExpire}
                                                                        onChange={(event) => {
                                                                            setFieldValue(`credentials.${index}.doesntExpire`, event.target.checked)
                                                                        }}
                                                                        className="mb-0"
                                                                        labelClassName="partner-text-grey font-size-16 mb-0 mt-1"
                                                                    />
                                                                </div>
                                                                <div
                                                                    className="d-flex justify-content-between align-items-center mt-3 gap-3">
                                                                    <div>
                                                                        <p className="font-weight-semibold mb-1">Upload
                                                                            documents</p>
                                                                        <p className="partner-text-grey font-size-16 mb-0">Attach
                                                                            at least one relevant file to verify
                                                                            credential</p>
                                                                    </div>
                                                                    <div>
                                                                        <Button
                                                                            color={BUTTON_COLORS.primary}
                                                                            type="button"
                                                                            className="sign-up-submit"
                                                                            disabled={values.credentials[index].files?.length === FILES_MAX_COUNT}
                                                                            onClick={() => {
                                                                                updateOpenUploadDocument((prevState) => ({
                                                                                    ...prevState,
                                                                                    [index]: true
                                                                                }));
                                                                            }}
                                                                        >
                                                                        <span className="mx-4-5">
                                                                            <Icon width={20} height={20}
                                                                                  icon="partnerUpload"
                                                                                  className="me-2"/>
                                                                            Upload file(s)
                                                                        </span>
                                                                        </Button>
                                                                    </div>
                                                                    {openUploadDocument?.[index] === true &&
                                                                    <UploadProfessionalDocuments
                                                                        key={index}
                                                                        isOpen={openUploadDocument}
                                                                        index={index}
                                                                        updateIsOpen={updateOpenUploadDocument}
                                                                        files={values.credentials[index].files}
                                                                        setFieldValue={setFieldValue}
                                                                        setFieldTouched={setFieldTouched}
                                                                        name={`credentials.${index}.files`}
                                                                    />}
                                                                </div>
                                                                {!!values.credentials[index]?.files?.length && <div
                                                                    className={classNames(values.credentials[index]?.files?.length >= 2 && "partner-document-container", "mt-3")}>
                                                                    {values.credentials[index]?.files.map((file, indexFiles) => {
                                                                        return <div key={indexFiles}>
                                                                            <div
                                                                                className={classNames(classes.FileItem, "d-flex align-items-center justify-content-between")}>
                                                                                <div
                                                                                    className="d-flex align-items-center">
                                                                                    <Icon icon="partnerDocument"
                                                                                          width={32} height={32}/>
                                                                                    <div
                                                                                        className="d-flex flex-column justify-content-between px-3">
                                            <span
                                                className={classNames(classes.TextBlack, values.credentials[index]?.files?.length >= 2 ? classes.FileNameStep2 : classes.FileName, "mb-1 font-size-16")}>
                                                <a
                                                    href={file.link}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    className="partner-text-black">{file.name}</a>
                                            </span>
                                                                                        <span
                                                                                            className={classNames(classes.TextMuted, "font-size-14")}>
                                                {formatBytes(file.bytes, 2)}
                                            </span>
                                                                                    </div>
                                                                                </div>
                                                                                <Icon icon="partnerTrash"
                                                                                      className="cursor-pointer ms-3"
                                                                                      onClick={() => deleteDocument(`credentials.${index}.files`, values.credentials[index]?.files, indexFiles, setFieldValue)}/>
                                                                            </div>
                                                                        </div>
                                                                    })

                                                                    }
                                                                </div>}
                                                            </div>
                                                        })}

                                                        {values.credentials?.length < MAX_CREDENTIALS_COUNT &&
                                                        <div
                                                            className="partner-text-primary text-center w-100 cursor-pointer mt-4-5"
                                                            onClick={
                                                                () => {
                                                                    push(initialCredentialsValues);
                                                                }
                                                            }
                                                        >
                                                            + Add one more professional credential
                                                        </div>
                                                        }
                                                    </div>
                                                )
                                            }}
                                        </FieldArray>
                                        <Button
                                            color={BUTTON_COLORS.primary}
                                            type="submit"
                                            loading={isLoading || isSubmitting}
                                            className="sign-up-submit mt-4-5 w-100 mb-5"
                                            disabled={
                                                isLoading || requestError ||
                                                !(isValid && dirty)
                                            }
                                        >
                                            Continue
                                        </Button>
                                    </form>
                                )
                            }}
                        </Formik>
                    </div>
                </div>
            </div>
        </AuthPartnerLayout>
    )
}

export default Step1