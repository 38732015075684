import {ADMINS_GROUP_LINKS} from "../config";

export const ADMINS_LIST_TABS = {
    ADMINS_LIST: "Admins",
    ROLES_LIST: "Roles",
}

export const ADMINS_TABS_LINKS = {
    [ADMINS_LIST_TABS.ADMINS_LIST]: ADMINS_GROUP_LINKS.ADMINS_LIST,
    [ADMINS_LIST_TABS.ROLES_LIST]: ADMINS_GROUP_LINKS.ROLES_LIST,
};
