import React from "react";
import {formatISODate} from "../../../../../base/helpers/date";
import joinClassNames from "../../../../../base/helpers/joinClassNames";
import {UncontrolledTooltip} from "reactstrap";

export const columns = [
    {
        Header: "#",
        width: 10,
        Cell: (cellProps) => {
            return <label style={{width: "10px", fontWeight: '400'}} className="mb-0 text-nowrap">{cellProps.row.index + 1 + '.'}</label>;
        }
    },
    {
        Header: 'Credential name',
        accessor: 'name',
        width: 150,
        Cell: (cellProps ) => {
            const index = cellProps.row.index
            const value = cellProps.row.original.name
            return <>
                <label id={`credential-name-${index}`} style={{width: "150px", overflow: "hidden", fontWeight: '400'}} className="mb-0 text-nowrap text-truncate">{value}</label>
                <UncontrolledTooltip
                    popperClassName={joinClassNames(
                        "tooltip-alternative-name"
                    )}
                    placement="bottom"
                    target={`credential-name-${index}`}

                >
                    {value}
                </UncontrolledTooltip>
            </>
        },
    },
    {
        Header: 'Issuing organization',
        accessor: 'issuingOrganisation',
        width: 150,
        Cell: (cellProps) => {
            const index = cellProps.row.index
            const value = cellProps.row.original.issuingOrganisation
            return <>
                <label id={`issuing-organization-${index}`} style={{width: "150px", overflow: "hidden", fontWeight: '400'}} className="mb-0 text-nowrap text-truncate">{value}</label>
                <UncontrolledTooltip
                    popperClassName={joinClassNames(
                        "tooltip-alternative-name"
                    )}
                    placement="bottom"
                    target={`issuing-organization-${index}`}

                >
                    {value}
                </UncontrolledTooltip>
                </>
        },
    },
    {
        Header: 'ID',
        accessor: 'referenceNumber',
        width: 80,
        Cell: (cellProps) => {
            const index = cellProps.row.index
            const value = cellProps.row.original.referenceNumber
            return <>
                <label id={`reference-number-${index}`} style={{width: "80px", overflow: "hidden", fontWeight: '400'}} className="mb-0 text-nowrap text-truncate fon">{value}</label>
                <UncontrolledTooltip
                    popperClassName={joinClassNames(
                        "tooltip-alternative-name"
                    )}
                    placement="bottom"
                    target={`reference-number-${index}`}

                >
                    {value}
                </UncontrolledTooltip>
            </>
        },
    },
    {
        Header: 'Issue date',
        accessor: 'issueDate',
        width: 70,
        Cell: ({value}) => {
            return formatISODate(value);
        },
    },
    {
        Header: 'Expiration date',
        accessor: 'expirationDate',
        width: 100,
        Cell: ({value}) => {
            return value?.length ? formatISODate(value) : "-";
        },
    },
]