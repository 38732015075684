export const DEFAULT_DEBOUNCE_DELAY = 1000;
export const DEFAULT_TABLE_LIMIT = 10

export const RECOMMENDATIONS_FILE_TYPE = 1;
export const PROGRAMS_FILE_TYPE = 12;
export const FOODINTOLERANCE_FILE_TYPE = 8;
export const CSV_FILE_TYPE = 3;
export const ASSETS_FILE_TYPE = 15;
export const PRACTITIONER_CREDENTIAL_FILE_TYPE = 19;

export const WELLNESS_FILE_TYPE = 17;

export const COACH_FILE_TYPE = 16;
export const EMPTY_ARRAY = [];

export const GENDERS = ["", "Male", "Female"];

export const EMPTY_FILE_SIZE = 0;
export const EMPTY_FILE_ERROR = "The file is empty.";
export const FILE_HAS_INCORRECT_STRUCTURE = "File has incorrect structure. Please, check.";

export const BYTES_IN_KILOBYTE = 1024;
export const KILOBYTES_IN_MEGABYTE = 1024;
export const MAX_DNA_CSV_FILE_SIZE = 20 * BYTES_IN_KILOBYTE * KILOBYTES_IN_MEGABYTE; // 20MB
export const ALLOWED_DNA_CSV_FILE_EXTENSION = ["csv"];
export const ERROR_DNA_CSV_FILE_SIZE_MESSAGE = `Invalid file size. Max. size ${MAX_DNA_CSV_FILE_SIZE/KILOBYTES_IN_MEGABYTE/BYTES_IN_KILOBYTE} mb`;
export const ERROR_ALLOWED_DNA_CSV_FILE_TYPE_MESSAGE = "File is not supported. Please upload a csv format file";
export const REACT_DROPZONE_INVALID_FILE_TYPE_ERROR = "file-invalid-type";
export const REACT_DROPZONE_INVALID_FILE_SIZE_ERROR = "file-too-large";
export const CSV_FILE_MASK = {
  "text/csv": [".csv"]
}
export const MAX_LOGO_FILE_SIZE = 2 * BYTES_IN_KILOBYTE * KILOBYTES_IN_MEGABYTE; // 2MB
export const ALLOWED_LOGO_FILE_EXTENSION = ["jpg", "jpeg", "png"];
export const ALLOWED_LOGO_FILE_EXTENSION_EXTENDED = ["jpg", "jpeg", "png"];
export const LOGO_FILE_MASK = {
  "image/jpeg": [".jpg", ".jpeg"],
  "image/png": [".png"]
}
export const LOGO_FILE_MASK_EXTENDED = {
  "image/jpeg": [".jpg", ".jpeg"],
  "image/png": [".png"],
}
export const ERROR_ALLOWED_LOGO_FILE_TYPE_MESSAGE = "File is not supported. Please upload a jpg, jpeg or png format file";
export const ERROR_ALLOWED_LOGO_FILE_TYPE_EXTENDED_MESSAGE = "File is not supported. Please upload a jpg, jpeg, svg or png format file";
export const ERROR_LOGO_FILE_SIZE_MESSAGE = `Invalid file size. Max. size ${MAX_LOGO_FILE_SIZE/KILOBYTES_IN_MEGABYTE/BYTES_IN_KILOBYTE} mb`;

export const DNA_SCV_FILE_MANDATORY_COLUMNS = [
  "SID",
  "Sentrix_ID",
  "Sentrix_Position"
];

export const USER_ROLES = {
  user: 1,
  admin: 2,
  superAdmin: 3,
  practitioner: 4
};

export const DATA_ACCESS_STATUSES = {
  notSent: 0,
  pending: 1,
  approved: 2,
  declined: 3
};

export const ADMINS_MANAGEMENT_STATUSES = {
  active: 1,
  invited: 2,
  blocked: 3
}

export const ADMINS_MANAGEMENT_STATUSES_NAMES = {
  [ADMINS_MANAGEMENT_STATUSES.active]: 'Active',
  [ADMINS_MANAGEMENT_STATUSES.invited]:  'Invited',
  [ADMINS_MANAGEMENT_STATUSES.blocked]: 'Blocked'
}

export const DATA_ACCESS_STATUSES_LABELS = {
  [DATA_ACCESS_STATUSES.notSent]: "Not sent",
  [DATA_ACCESS_STATUSES.pending]: "Pending",
  [DATA_ACCESS_STATUSES.approved]: "Approved",
  [DATA_ACCESS_STATUSES.declined]: "Declined"
};

export const DATA_ACCESS_STATUSES_COLORS = {
  [DATA_ACCESS_STATUSES.notSent]: "grey-badge",
  [DATA_ACCESS_STATUSES.pending]: "orange-badge",
  [DATA_ACCESS_STATUSES.approved]: "green-badge",
  [DATA_ACCESS_STATUSES.declined]: "red-badge"
};


