import React from "react";
import App from "./App";
import Context from "./Context";
// import * as serviceWorker from "./serviceWorker"
import { createRoot } from 'react-dom/client';
import DynamicTheme from "./base/components/DynamicTheme";
import Storyblock from "./base/components/Storyblock";

import "./assets/scss/theme.scss";
import "./assets/scss/partners.scss";

const Application = (
    <div className="fontStyles">
        <Context>
            <Storyblock>
                <DynamicTheme/>
                <App/>
            </Storyblock>
        </Context>
    </div>
);

const container = document.getElementById('root');
const root = createRoot(container);
root.render(Application);

// serviceWorker.unregister()
