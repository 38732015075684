import React from "react";
import {Route, Routes} from "react-router-dom";
import {Container} from "reactstrap";

import {PRACTITIONERS_GROUP_ROUTES} from "./config";
import PractitionersList from "./List";

export default function Practitioners() {
    return(
        <Container fluid className="content">
            <Routes>
                <Route
                    path={PRACTITIONERS_GROUP_ROUTES.LIST}
                    element={<PractitionersList/>}
                />
            </Routes>
        </Container>
    )
}