import {MEAL_TYPES} from "../../../base/constants/foods";

const URL_WITH_THUMBNAIL = 'https://copy-of-meta-nutrition-images'

export const getThumbnailImage = (path, size) => {
    if (!path.startsWith(URL_WITH_THUMBNAIL)) {
        return path
    }

    const parts = path.split("/");
    if (parts && parts.length > 0) {
        let url = "";
        for (let i = 0; i < parts.length - 1; i++) {
            url += `${parts[i]}/`;
        }
        return `${url}${size}x${size}/${parts[parts.length - 1]}`;
    }
    return undefined;
};

export const groupRecipesByMealTime = (recipes) => {
    const groupedRecipes = Object.fromEntries(
        Object.values(MEAL_TYPES).map(meal => [meal, []])
    );

    if (!recipes?.length) return groupedRecipes;

    recipes.forEach(({ foodName, kcal, mealTime }) => {
        mealTime.forEach(meal => {
            if (groupedRecipes[meal]) {
                groupedRecipes[meal].push({ foodName, kcal });
            }
        });
    });

    return groupedRecipes;
}

export const findReadyInTime = (arr) => {
    if (!arr?.length) return null;
    for (let item of arr) {
        if (item.range && item.range.readyInTime) {
            return item.range.readyInTime;
        }
    }
    return null;
}

export const findComplexity = (arr) => {
    if (!arr?.length) return null;
    for (let item of arr) {
        if (item.range && item.range.complexity) {
            return item.range.complexity;
        }
    }
    return null;
}

export const findNIKcalValues = (arr) => {
    const results = [];

    if (!arr?.length) return results;

    for (let item of arr) {
        if (item.bool && item.bool.should) {
            for (let shouldItem of item.bool.should) {
                if (shouldItem.range && shouldItem.range['NI-kcal-value']) {
                    results.push(shouldItem.range['NI-kcal-value']);
                }
            }
        }
    }

    return results;
}

export const findAllergens = (arr) => {
    if (!arr?.length) return null;
    for (let item of arr) {
        if (item.terms && item.terms.allergens) {
            return item.terms.allergens;
        }
    }
    return null;
}

export const findAllRangeValues = (arr) => {
    const rangeValues = [];
    if (!arr?.length) return rangeValues;
    for (let item of arr) {
        if (item.range) {
            // Get the key of the range object (e.g., 'NI-protein-value', 'NI-carbs-value')
            const rangeKey = Object.keys(item.range)[0];
            // Remove 'NI-' prefix and '-value' suffix
            const cleanedType = rangeKey.replace(/^NI-/, '').replace(/-value$/, '');
            const capitalizedType = cleanedType.charAt(0).toUpperCase() + cleanedType.slice(1);
            rangeValues.push({
                type: capitalizedType,
                ...item.range[rangeKey]
            });
        }
    }

    return rangeValues;
}