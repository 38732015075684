import React, {useEffect, useState} from "react";
import {Navigate, Route, Routes, useLocation, useNavigate} from "react-router-dom";
import BaseLayoutWithCard from "../../../../base/components/BaseLayoutWithCard";
import {Nav, NavItem, NavLink, Spinner} from "reactstrap";
import classNames from "classnames";
import {ADMINS_LIST_TABS, ADMINS_TABS_LINKS} from "./constants";
import {ADMINS_GROUP_LINKS} from "../config";
import Admins from "./Admins";
import {ADMINS_LISTS_ROUTES} from "./config";
import Roles from "./Roles";
import {useService} from "../../../../base/hooks/useService";
import OrganisationsService from "../../../../services/OrganisationsService";
import {useLoading} from "../../../../base/hooks/useLoading";
import useConfig from "../../../../base/hooks/useConfig";

const breadcrumbs = {
    title: "Admins", breadcrumbItems: []
}

export default function Lists() {
    /**
     * @type {OrganisationsService}
     */
    const organisationsService = useService(OrganisationsService);
    const [isLoading, { registerPromise }] = useLoading(true);
    let navigate = useNavigate();
    const { themeSettings } = useConfig();

    const location = useLocation()
    const [currentOrganisation, setCurrentOrganisation] = useState()

    const getOrganisationDetails = () => {
        registerPromise(organisationsService.getCurrentOrganisations()
            .then((data) => {
                setCurrentOrganisation(data)
            }))
    }

    useEffect(() => {
        getOrganisationDetails()
    }, [])

    return (
        <BaseLayoutWithCard breadcrumbs={breadcrumbs}>
            {isLoading ?
                <section className="d-flex justify-content-center my-3">
                    <Spinner color="info" className="mx-auto"/>
                </section>
                :
                <div className="d-flex gap-3 mb-4">
                    {themeSettings?.organisationLogo && <div className="bg-light-gray rounded-1 p-1">
                        <img src={themeSettings?.organisationLogo} alt="Organisation logo"/>
                    </div>}
                    <div className="d-flex flex-column">
                        <p className="text-current mb-1 font-weight-semibold font-size-15">{currentOrganisation?.name}</p>
                        <p className="text-secondary mb-0">{currentOrganisation?.domain}</p>
                    </div>
                </div>
            }
            <Nav tabs className="nav-tabs-custom mt-1">
                {Object.values(ADMINS_LIST_TABS).map((label, index) => (
                    <NavItem key={`navItem${index}`}>
                        <NavLink
                            className={classNames(
                                {
                                    active: location.pathname === ADMINS_TABS_LINKS[label]
                                },
                                "cursor-pointer py-1 mx-1"
                            )}
                            onClick={() => navigate(ADMINS_TABS_LINKS[label])}
                        >
                            <span>{label}</span>
                        </NavLink>
                    </NavItem>
                ))}
            </Nav>
            <Routes>
                <Route
                    path={ADMINS_LISTS_ROUTES.ADMINS_LIST}
                    element={<Admins/>}
                />

                <Route
                    path={ADMINS_LISTS_ROUTES.ROLES_LIST}
                    element={<Roles/>}
                />

                <Route path={"/"} element={<Navigate to={ADMINS_GROUP_LINKS.ADMINS_LIST}/>}/>
            </Routes>
        </BaseLayoutWithCard>
    );
}