// Libs
import React, {useEffect, useRef, useState} from "react";
import {Badge, Dropdown, DropdownMenu, DropdownToggle, ListGroup, Spinner} from "reactstrap";
import classnames from "classnames";

// Components, Views, Screens
import Icon from "../../../../../../base/components/Icon";

// Hooks, Utils, Helpers
import {useService} from "../../../../../../base/hooks/useService";
import PartnersService from "../../../../../../services/PartnersService";
import {useLoading} from "../../../../../../base/hooks/useLoading";

// Styles, assets
import classes from "./SpecialisationsDropdown.module.scss";
import {useField} from "formik";
import Checkbox from "../../../../../../base/components/Checkbox";


export const SpecialisationsDropdown = ({
                                            name,
                                            placeholder,
                                            label,
                                            afterOnChange = () => {}
                                        }) => {
    /**
     * @type {PartnersService}
     */
    const partnersService = useService(PartnersService);
    const [field, meta, helpers] = useField(name)
    const [isLoading, {registerPromise}] = useLoading(true);
    const [specialisations, updateSpecialisations] = useState([]);
    const dropdownRef = useRef(null);
    const [isOpen, updateIsOpen] = useState(false);
    const [inputValue, setInputValue] = useState('');
    const searchInputRef = useRef(null)

    const selectedSpecializations = specialisations.filter((spec) => field.value.includes(spec.id))

    const filteredSpecialisations = specialisations.filter((spec) =>
        spec.name.toLowerCase().includes(inputValue.toLowerCase()),
    )

    const handleSelect = async (id) => {
        const currentValue = field.value || []
        const newValue = currentValue.includes(id) ? currentValue.filter((v) => v !== id) : [...currentValue, id]
        afterOnChange();
        setInputValue('');
        searchInputRef.current.focus()
        await helpers.setTouched(true, true)
        return helpers.setValue(newValue)
    }

    const handleRemove = async (id) => {
        await helpers.setTouched(true, true)
        afterOnChange();
        return helpers.setValue(field.value.filter((v) => v !== id))
    }

    const getListSpecialisations = () => {
        registerPromise(partnersService.specialisationsList())
            .then(({data}) => {
                updateSpecialisations(data)
            })
    }

    useEffect(() => {
        getListSpecialisations()
    }, [])

    useEffect(() => {
        if (isOpen && searchInputRef.current) {
            searchInputRef.current.focus()
        }
    }, [isOpen])

    return (
        <>
            {!!label && <label>{label}</label>}
            <section className="d-flex align-items-center w-100" ref={dropdownRef}>
                <Dropdown
                    isOpen={isOpen}
                    toggle={(e) => {
                        if (!isOpen) {
                            updateIsOpen(true);
                        } else if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
                            setInputValue('');
                            updateIsOpen(false);
                        }
                    }}
                    className="d-inline-block filter-dropdown cursor-pointer result-filter w-100"
                    direction="down"
                >
                    <DropdownToggle
                        className={classnames(
                            'filter-toggle w-100',
                            {'with-border': isOpen, "is-invalid select-invalid": !!meta.error && !!meta.touched})}
                        tag="section"
                    >
                        <span
                            className={classnames('user-select-none text-truncate', {'text-secondary': !selectedSpecializations?.length})}
                        >
                            {isOpen && (
                                <input
                                    ref={searchInputRef}
                                    type="text"
                                    value={inputValue}
                                    onChange={(e) => setInputValue(e.target.value)}
                                    autoFocus
                                    style={{ width: `${inputValue.length + 3}ch` }}
                                    className={classnames(
                                        selectedSpecializations?.length && "mb-1",
                                        'd-inline-block px-2 py-1 rounded-3 me-2 h-100 bg-transparent border-1 border-primary border shadow-none outline-none font-size-16'
                                    )}
                                />
                            )}
                            {
                                !selectedSpecializations?.length
                                    ? !isOpen && (placeholder || 'Select tags')
                                    : <div className="d-flex flex-wrap gap-2">
                                        {(selectedSpecializations).map((spec) => {
                                            return (
                                                <Badge key={spec.id}
                                                       color="primary"
                                                       className={classnames(classes.Badge)}
                                                >
                                                    <span className={classnames(classes.BadgeText, "text-truncate")}>{spec.name}</span>
                                                    <Icon
                                                        onClick={(e) => {
                                                            e.stopPropagation()
                                                            return handleRemove(spec.id)
                                                        }}
                                                        className="ms-2"
                                                        icon={'closeFilled'}
                                                        width={20}
                                                        height={20}
                                                    />
                                                </Badge>
                                            )
                                        })}
                                    </div>
                            }
                        </span>
                        <i className={classnames('mdi mdi-chevron-down pointer-events-none user-select-none', {'mdi-rotate-180': isOpen})}/>
                    </DropdownToggle>

                    <DropdownMenu className={classnames(classes.ItemsMenu, "filter-menu pb-1 px-1 w-100 top-50")}
                                  flip={false}>
                        <section>
                            <ListGroup>
                                <div className={classnames(classes.ItemsWrapper, "custom-scrollbar")}>
                                    {!filteredSpecialisations.length && inputValue.length && <div className="text-center d-flex font-size-16 align-items-center justify-content-center partner-text-grey">
                                        <div>Not found results</div>
                                    </div>}
                                    {(filteredSpecialisations ?? specialisations).map((spec) => {
                                        return (
                                            <div
                                                key={spec.id}
                                                onClick={(event) => {
                                                    event.stopPropagation()
                                                    return handleSelect(spec.id)
                                                }}
                                                className={classnames(classes.Item, "custom-scrollbar", "d-flex align-items-center")}

                                            >
                                                <Checkbox
                                                    onChange={(event) => {
                                                        event.stopPropagation()
                                                        return handleSelect(spec.id)
                                                    }}
                                                    value={!!field.value.includes(spec.id)}
                                                    className={classnames( "h-4 w-4")}
                                                />
                                               {spec.name}
                                            </div>

                                        )
                                    })}
                                    <div className="d-flex justify-content-center">
                                        {isLoading && <Spinner size="sm" color="primary"/>}
                                    </div>
                                </div>
                            </ListGroup>
                        </section>
                    </DropdownMenu>
                </Dropdown>
            </section>
            {meta?.error && meta?.touched &&
            <div className="invalid-feedback d-block">{meta.error}</div>
            }
        </>
    )
}

